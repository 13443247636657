import React, { useState } from 'react'
import { styled, Tooltip, Typography, useMediaQuery } from '@mui/material'
import { Info } from '@mui/icons-material'
import { TrackedLink } from '@flock/shared-ui'
import { useMutation } from '@apollo/client'
import { useSnackbar } from '@flock/flock-component-library'
import theme from '@flock/shared-ui/src/theme/theme'
import {
  InvestorEditLegalEntityMutationDocument,
  InvestorGetDistributionPreferencesDocument,
  InvestorGetLegalEntitiesByInvestorAccountUuidDocument,
  InvestorUpdateDistributionPreferencesDocument,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import { formatDollarsAndCents } from '@flock/utils'
import PreferencesModal from './PreferencesModal'
import PreferencesMobileDrawer from './PreferencesMobileDrawer'

type FlexibleDistributionSectionProps = {
  cashDistributionPercentage: number
  distributionPreferences: any
  hasLegalEntityBankAccountData: any
  legalEntityUuid: string
}

const SettingsGroupHeader = styled(Typography)({
  paddingBottom: '0.4rem',
  display: 'flex',
  justifyContent: 'space-between',
})

const SettingsGroupHeaderWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'baseline',
})

const SettingsGroupContainer = styled('div')({
  paddingTop: '0.7rem',
  paddingBottom: '0.7rem',
})

const StyledTooltip = styled((props: any) => (
  <Tooltip classes={{ popper: props.className }} {...props} />
))`
  & .MuiTooltip-tooltip {
    background-color: white;
    color: black;
    border-radius: 0;
    max-width: 12rem;
    line-height: 150%;
    box-shadow: rgba(0, 0, 0, 0.2) 0 2px 5px;
  }

  & .MuiTooltip-arrow::before {
    color: white;
  }
`

const StyledHelp = styled(Info)({
  fontSize: '1rem',
  cursor: 'pointer',
  marginLeft: '0.25rem',
  marginBottom: '-0.1rem',
})

const FlexibleDistributionSection = (
  props: FlexibleDistributionSectionProps
) => {
  const {
    cashDistributionPercentage,
    distributionPreferences,
    hasLegalEntityBankAccountData,
    legalEntityUuid,
  } = props
  const [editLegalEntity] = useMutation(InvestorEditLegalEntityMutationDocument)
  const [updateDistributionPreferences] = useMutation(
    InvestorUpdateDistributionPreferencesDocument
  )
  const [flexibleDistributionsModalOpen, setFlexibleDistributionsModalOpen] =
    useState(false)

  const openFlexibleDistributionsModal = () => {
    setFlexibleDistributionsModalOpen(true)
  }

  const closeFlexibleDistributionsModal = () => {
    setFlexibleDistributionsModalOpen(false)
  }

  const { notify } = useSnackbar()

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  let disableCashDistributionChange = false
  if (!hasLegalEntityBankAccountData) {
    disableCashDistributionChange = true
  }

  const disableMicroredemptions = !distributionPreferences?.legalEntityUuid

  const confirmDistributions = async (
    newCashDistributionPercentage: number
  ) => {
    let cashDistributionEnabled = false
    if (newCashDistributionPercentage > 0) {
      // Enable cash distribution
      cashDistributionEnabled = true
    }

    try {
      await editLegalEntity({
        variables: {
          editLegalEntityInput: {
            uuid: legalEntityUuid,
            cashDistributionEnabled,
            flexibleDistributionPercent: newCashDistributionPercentage,
          },
        },
        refetchQueries: [
          InvestorGetLegalEntitiesByInvestorAccountUuidDocument,
          InvestorGetDistributionPreferencesDocument,
        ],
      })

      if (!disableMicroredemptions) {
        const cashDistributionCents =
          (newCashDistributionPercentage / 100) *
          distributionPreferences?.distributionAllowedCents

        const microredemptionAmountCents =
          newCashDistributionPercentage !== 100
            ? 0
            : distributionPreferences?.microredemptionAmountCents

        await updateDistributionPreferences({
          variables: {
            input: {
              legalEntityUuid,
              flexibleDistributionCents: Math.round(cashDistributionCents),
              microredemptionAmountCents,
            },
          },
          refetchQueries: [
            InvestorGetDistributionPreferencesDocument,
            InvestorGetLegalEntitiesByInvestorAccountUuidDocument,
          ],
        })
      }

      notify('Successfully updated distribution preference.', 'success')
    } catch (e) {
      notify(
        'An error has occurred and our team has been notified. Please refresh and try again or contact our team for assistance.',
        'error'
      )
    }
  }

  const current = new Date()
  current.setHours(0, 0, 0, 0)

  return (
    <>
      <SettingsGroupContainer>
        <SettingsGroupHeaderWrapper>
          <SettingsGroupHeader variant="p1">
            Distribution Preference
          </SettingsGroupHeader>
          {disableCashDistributionChange ? (
            <StyledTooltip
              title="A bank account must be linked to your account to enable Cash Distributions."
              arrow
              placement="top"
              enterTouchDelay={0}
            >
              <StyledHelp sx={{ color: `${theme.palette.gray7.main}50` }} />
            </StyledTooltip>
          ) : (
            <TrackedLink onClick={openFlexibleDistributionsModal}>
              Edit
            </TrackedLink>
          )}
        </SettingsGroupHeaderWrapper>
        {cashDistributionPercentage === 100 ? (
          <Typography variant="p2">
            You have elected to receive 100%{' '}
            {distributionPreferences?.flexibleDistributionCents > 0
              ? `(${formatDollarsAndCents(
                  distributionPreferences?.flexibleDistributionCents / 100
                )}) `
              : ''}
            of your distribution in cash.
          </Typography>
        ) : (
          <>
            {cashDistributionPercentage > 0 ? (
              <Typography variant="p2">
                You have elected to receive {cashDistributionPercentage}%{' '}
                {distributionPreferences?.flexibleDistributionCents > 0
                  ? `(${formatDollarsAndCents(
                      distributionPreferences?.flexibleDistributionCents / 100
                    )}) `
                  : ''}
                in cash and reinvest {100 - cashDistributionPercentage}% and
                receive shares for your distribution.
              </Typography>
            ) : (
              <Typography variant="p2">
                You have elected to reinvest 100% of your quarterly
                distribution.
              </Typography>
            )}
          </>
        )}
        {isMobile ? (
          <PreferencesMobileDrawer
            open={flexibleDistributionsModalOpen}
            distributionPercent={cashDistributionPercentage}
            disableMicroredemptions={disableMicroredemptions}
            distributionPreferences={distributionPreferences}
            onSubmit={confirmDistributions}
            onClose={closeFlexibleDistributionsModal}
          />
        ) : (
          <PreferencesModal
            open={flexibleDistributionsModalOpen}
            distributionPercent={cashDistributionPercentage}
            disableMicroredemptions={disableMicroredemptions}
            distributionPreferences={distributionPreferences}
            onSubmit={confirmDistributions}
            onClose={closeFlexibleDistributionsModal}
          />
        )}
      </SettingsGroupContainer>
    </>
  )
}

export default FlexibleDistributionSection

import React from 'react'
import {
  AuditLogTable,
  useSnackbar,
  CircularProgress,
  AuditTableEntry,
} from '@flock/flock-component-library'
import { useQuery } from '@apollo/client'
import {
  Core_AuditLog,
  InvestorDashboardGetAuditLogsDocument,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import {
  Box,
  IconButton,
  Modal,
  Paper,
  styled,
  Typography,
} from '@mui/material'
import { Close } from '@mui/icons-material'
import { formatIntegerCents } from '@flock/utils'
import { useInvestorAccountContext } from '../../../InvestorAccountContext'

const LoadingWrapper = styled('div')({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
})

const ModalWrapper = styled(Paper)({
  position: 'absolute',
  top: '10%',
  left: '50%',
  transform: 'translate(-50%)',
  width: '544px',
  minHeight: '400px',
  maxHeight: '75vh',
  overflowY: 'auto',
  padding: '48px',
  borderRadius: '16px',
})

type AuditLogModalProps = {
  open: boolean
  entityUuid: string
  onClose: () => void
  entityIsInvestor?: boolean
}

const AuditLogModal = (props: AuditLogModalProps) => {
  const {
    open,
    entityUuid: selectedLegalEntityUuid,
    onClose,
    entityIsInvestor,
  } = props
  const { investorContextLoading } = useInvestorAccountContext()

  const { notify } = useSnackbar()

  const { data: auditLogData, loading: auditLogsLoading } = useQuery(
    InvestorDashboardGetAuditLogsDocument,
    {
      skip: investorContextLoading,
      variables: {
        input: {
          tags: [selectedLegalEntityUuid],
          isInvestor: entityIsInvestor,
        },
      },
      onError: () => {
        notify('An error occurred while fetching activity logs', 'error')
      },
    }
  )

  let userProcessedData: AuditTableEntry[] = []

  userProcessedData =
    auditLogData?.auditLogs?.auditLogs?.reduce(
      (resArray: AuditTableEntry[], log: Core_AuditLog | undefined | null) => {
        const previousJson = JSON.parse(log?.previousValue || '{}')
        const updatedJson = JSON.parse(log?.updatedValue || '{}')
        const { tableName } = log as Core_AuditLog

        let eventName = ''
        // For changes on legal entities, we want to alter the event name based on what was updated
        const alteredKeys = Object.keys(updatedJson)
        const singleFieldAltered = alteredKeys.length === 1
        const operationName = log?.operation

        switch (tableName) {
          case 'legal_entities':
            // Multiple fields updated
            if (
              alteredKeys.includes('plaidAccessToken') ||
              alteredKeys.includes('basistheoryToken')
            ) {
              eventName = 'Bank account updated'
            } else if (
              singleFieldAltered &&
              alteredKeys.includes('cashDistributionEnabled')
            ) {
              return resArray
            } else if (
              (operationName === 'update' &&
                (alteredKeys.includes('name') ||
                  alteredKeys.includes('phoneNumber') ||
                  alteredKeys.includes('email'))) ||
              alteredKeys.includes('primaryMailingAddressUuid')
            ) {
              eventName = 'Contact information updated'
            } else if (
              operationName === 'update' &&
              alteredKeys.includes('notifyMicroredemptionsReady')
            ) {
              if (updatedJson.notifyMicroredemptionsReady) {
                eventName = 'Microredemption availability notifications enabled'
              } else {
                return resArray
              }
            } else if (
              operationName === 'update' &&
              alteredKeys.includes('flexibleDistributionPercent')
            ) {
              return resArray
            } else {
              return resArray
            }
            break
          case 'legal_entities_distribution_preferences':
            if (
              operationName === 'update' &&
              alteredKeys.includes('microredemptionAmountCents')
            ) {
              eventName = `Microredemption preference updated to ${formatIntegerCents(
                updatedJson.microredemptionAmountCents
              )}`
            } else {
              return resArray
            }
            break
          case 'investor_accounts':
            if (operationName === 'update') {
              eventName = 'Investor contact information updated'
            } else {
              return resArray
            }
            break
          default:
            // If it's not one of the above cases, we don't include the event
            return resArray
        }
        const result = {
          ...log,
          eventName,
          cumulativeEquityValue: '',
          previousValue: previousJson,
          updatedValue: updatedJson,
        }

        resArray.push(result as AuditTableEntry)
        return resArray
      },
      []
    ) || []

  const fetchUserName = async (__: string, entityType: string) => {
    if (entityType === 'operator') {
      return 'Admin'
    } else {
      return 'User'
    }
  }

  const loading = auditLogsLoading

  return (
    <Modal open={open} onClose={onClose}>
      <ModalWrapper>
        {loading ? (
          <LoadingWrapper>
            <CircularProgress />
          </LoadingWrapper>
        ) : (
          <Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'baseline',
                paddingBottom: '0.7rem',
              }}
            >
              <Typography variant="h3">Audit Log</Typography>
              <IconButton onClick={onClose}>
                <Close />
              </IconButton>
            </Box>
            <AuditLogTable
              auditLogEntries={userProcessedData}
              fetchUserName={fetchUserName}
              showDetails={false}
              isTransaction={false}
              key={0}
            />
          </Box>
        )}
      </ModalWrapper>
    </Modal>
  )
}

AuditLogModal.defaultProps = {
  entityIsInvestor: false,
}

export default AuditLogModal

import {
  Box,
  IconButton,
  styled,
  Typography,
  CircularProgress,
  Drawer,
  Grid,
  Switch,
} from '@mui/material'
import { TrackedButton, CloseMobileIcon, TrackedLink } from '@flock/shared-ui'
import React, { ChangeEvent, useEffect, useState } from 'react'
import { Core_LegalEntityDistributionPreferences } from '@flock/flock-gql-server/src/__generated__/graphql'

const SettingsGroupHeaderWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
})

export type PreferencesModalProps = {
  open: boolean
  distributionPercent: number
  disableMicroredemptions: Boolean
  distributionPreferences: Core_LegalEntityDistributionPreferences
  onSubmit: (newValue: number, notifyPreference?: boolean) => Promise<void>
  onClose: () => void
}

const PreferencesMobileDrawer = (props: PreferencesModalProps) => {
  const { open, distributionPercent, onSubmit, onClose } = props
  const [loading, setLoading] = useState(false)
  const [percent, setPercent] = useState(distributionPercent as number)

  useEffect(() => {
    setPercent(distributionPercent as number)
  }, [distributionPercent])

  const trackingName = 'preferences-modal'

  const submitAndClose = async () => {
    setLoading(true)
    await onSubmit(percent)
    setLoading(false)
    onClose()
  }

  const onChange = (_: ChangeEvent<HTMLInputElement>, newValue: boolean) => {
    if (newValue) {
      setPercent(0)
    } else {
      setPercent(100)
    }
  }

  return (
    <Drawer open={open} PaperProps={{ sx: { width: '100%' } }}>
      <Box position="fixed" top="8px" right="8px">
        <IconButton onClick={onClose}>
          <CloseMobileIcon />
        </IconButton>
      </Box>
      <Grid columns={4} spacing={2} pl="32px" pr="32px">
        <Box width="100%" display="flex" flexDirection="column" gap="24px">
          <SettingsGroupHeaderWrapper
            sx={{ width: '100%', marginBottom: '1rem', pt: '64px' }}
          >
            <Typography variant="h3">Distribution Preference</Typography>
          </SettingsGroupHeaderWrapper>
          <Box display="flex" justifyContent="space-between">
            <Box display="flex" flexDirection="column">
              <Typography variant="p1">Reinvest distributions?</Typography>
              <Typography variant="p2">
                {percent === 100
                  ? 'You have elected to receive your distribution in cash.'
                  : 'You have elected to reinvest your distribution.'}
              </Typography>
            </Box>
            <Switch onChange={onChange} value={percent === 0} />
          </Box>
          <Box
            display="flex"
            justifyContent="flex-end"
            flexDirection="row"
            alignItems="center"
            gap="16px"
          >
            {loading ? (
              <CircularProgress />
            ) : (
              <>
                <TrackedLink
                  trackingConfig={{ name: `${trackingName}-cancelled` }}
                  variant="p1"
                  onClick={onClose}
                >
                  Cancel
                </TrackedLink>
                <TrackedButton
                  variant="primary"
                  size="smallForm"
                  onClick={submitAndClose}
                  sx={{
                    marginLeft: '1rem',
                  }}
                  trackingConfig={{ name: `${trackingName}-confirmed` }}
                >
                  Confirm
                </TrackedButton>
              </>
            )}
          </Box>
        </Box>
      </Grid>
    </Drawer>
  )
}

export default PreferencesMobileDrawer

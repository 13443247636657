import React from 'react'
import { SvgIcon, SvgIconProps, useTheme } from '@mui/material'

const OmSymbolLogo = (props: SvgIconProps) => {
  // @ts-ignore
  const { color, fill, stroke, sx, width, height, ...otherProps } = props // eslint-disable-line
  const theme = useTheme()

  let selectedColor = color

  // @ts-ignore
  if (color && theme.palette[color]) {
    // @ts-ignore
    selectedColor = theme.palette[color].main
  }

  const fillStyle = fill || selectedColor || undefined
  

  const styles = {
    width: 'inherit',
    height: 'inherit',
    fill: 'inherit',
    ...sx,
  }

  const cleanedPaths = () => (<svg width={width || 80} height={height || 80} viewBox="0 0 80 80" fill="none">
<path d="M40 36.3936C28.507 36.3936 18.4309 30.2435 12.7893 21.0106C11.8094 19.4077 9.40391 19.7942 8.95396 21.6232C8.33097 24.1548 8 26.805 8 29.5321C8.00649 47.5632 22.3289 62.0526 40.093 61.9999C57.723 61.9494 72 47.427 72 29.5189C72 26.7963 71.669 24.1504 71.046 21.621C70.5961 19.7898 68.1906 19.4055 67.2107 21.0084C61.5691 30.2435 51.4908 36.3936 40 36.3936Z" fill={fillStyle || '#0C1D3F'}/>
</svg>
)

  return (
    <SvgIcon component={cleanedPaths} inheritViewBox sx={styles} {...otherProps} />
  )
}

export default OmSymbolLogo

import React, { useCallback } from 'react'

import { useMutation, gql } from '@apollo/client'
import { GlobalTrackingProvider } from '@flock/shared-ui'
import {
  Core_CreateInvestorFrontendEventRequestInput,
  TrackingWrapperCreateInvestorFrontendEventDocument,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import * as Sentry from '@sentry/gatsby'
import { trackPage, track } from './analytics'
import { useInvestorAccountContext } from '../components/InvestorAccountContext'

export const CREATE_INVESTOR_FRONTEND_EVENT = gql`
  mutation TrackingWrapperCreateInvestorFrontendEvent(
    $input: Core_CreateInvestorFrontendEventRequestInput!
  ) {
    createInvestorFrontendEvent(input: $input) {
      _empty
    }
  }
`

type TrackingWrapperProps = {
  children: React.ReactNode
  groupedEvents?: { [key: string]: string }
}

const TrackingWrapper = (props: TrackingWrapperProps) => {
  const { children, groupedEvents } = props
  const [sendEvent] = useMutation(
    TrackingWrapperCreateInvestorFrontendEventDocument
  )

  const { investorAccount } = useInvestorAccountContext()

  const sendEventFunction = useCallback(
    async (input: Core_CreateInvestorFrontendEventRequestInput) => {
      try {
        await sendEvent({ variables: { input } })
      } catch (e) {
        // network errors can happen a lot due to how often we are sending events
        // ignore if it is a network error.
        if (
          e.message.includes('Failed to fetch') ||
          e.message.includes('Load failed')
        ) {
          return
        }
        Sentry.captureException(e, {
          extra: {
            actionType: input.actionType,
            slug: input.slug,
            urlPath: input.urlPath,
          },
        })
      }
    },
    [sendEvent]
  )

  const trackingFunctionWrapper = useCallback(
    (name: string, properties?: any, eventType?: string, slug?: string) => {
      track(
        name,
        properties,
        eventType,
        sendEventFunction,
        slug,
        investorAccount?.uuid || ''
      )
    },
    [sendEventFunction, investorAccount]
  )

  const trackPageFunctionWrapper = useCallback(
    (name: string, properties?: any, title?: string) => {
      trackPage(
        name,
        properties,
        sendEventFunction,
        investorAccount?.uuid || '',
        title
      )
    },
    [sendEventFunction, investorAccount]
  )

  return (
    <GlobalTrackingProvider
      siteName="investor-fe"
      trackFn={trackingFunctionWrapper}
      trackPageFn={trackPageFunctionWrapper}
      groupedEvents={groupedEvents || {}}
    >
      {children}
    </GlobalTrackingProvider>
  )
}

TrackingWrapper.defaultProps = {
  groupedEvents: {},
}

export default TrackingWrapper

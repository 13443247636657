import { Box, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useInvestorAccountContext } from '../../../InvestorAccountContext'
import BankAccountSection from '../../../InvestorDashboardComponents/Settings/BankAccount/BankAccountSection'

type BankAccountInputProps = {
  onChange: (value: any) => void
  onBlur: () => void
  error?: boolean
  helperText?: string
}

const BankAccountInput = (props: BankAccountInputProps) => {
  const { onChange, onBlur, error, helperText } = props
  const [plaidAccountData, setPlaidAccountData] = useState({
    publicToken: '',
    accountIds: [''],
  })

  const [hasLegalEntityBankAccountData, setHasLegalEntityBankAccountData] =
    useState<null | boolean>(null)

  const { selectedLegalEntity } = useInvestorAccountContext()

  useEffect(() => {
    if (hasLegalEntityBankAccountData) {
      onChange(hasLegalEntityBankAccountData)
      onBlur()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasLegalEntityBankAccountData])

  return (
    <Box display="flex" flexDirection="column" gap="12px">
      <BankAccountSection
        selectedLegalEntityUuid={selectedLegalEntity?.uuid as string}
        setOuterHidden={undefined}
        plaidAccountData={plaidAccountData}
        setPlaidAccountData={setPlaidAccountData}
        setHasLegalEntityBankAccountData={(updatedValue: boolean) => {
          setHasLegalEntityBankAccountData(updatedValue)
        }}
        showCashDistributionRequirement={false}
      />
      <Typography variant="p3" color={error ? 'error.main' : 'inherit'}>
        {helperText}
      </Typography>
    </Box>
  )
}

BankAccountInput.defaultProps = {
  error: false,
  helperText: '',
}

export default BankAccountInput

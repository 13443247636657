import React from 'react'

import {
  HouseOutlinedIcon,
  TrackedLink,
  OmLeftArrowFilledIcon,
} from '@flock/shared-ui'
import {
  ORDERS_URL,
  formatAddressString,
  formatCityStateZip,
} from '@flock/utils'
import { Card, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { navigate } from 'gatsby'
import { useInvestorAccountContext } from '../../InvestorAccountContext'

const ActiveOrdersCard = () => {
  const { activeOrderStatuses: inProgressOrders } = useInvestorAccountContext()
  const targetUrl = `${ORDERS_URL}/${inProgressOrders[0]?.orderUuid}` || ''
  const isSingleOrder = inProgressOrders.length === 1
  const inProgressOrder = inProgressOrders[0]
  const addresses = inProgressOrder.addresses!
  const titleText = isSingleOrder ? 'New Contribution' : 'Active Orders'
  const additionalField = isSingleOrder
    ? addresses.length - 1
    : inProgressOrders.length - 1
  const linkText = isSingleOrder ? 'IN PROGRESS' : 'VIEW ORDERS'

  return (
    <>
      <Card
        sx={{
          display: 'flex',
          backgroundColor: '#8778D920',
          borderRadius: '24px',
          height: '100%',
          transition: 'all 200ms ease',
          '&:hover': { cursor: 'pointer' },
        }}
        onClick={() => navigate(targetUrl)}
      >
        <Box
          display="flex"
          flexDirection="column"
          gap="12px"
          padding="24px"
          sx={{ textAlign: 'left' }}
          width="100%"
        >
          <Box display="flex" flexDirection="row" alignItems="center" gap="8px">
            <HouseOutlinedIcon width="24px" height="24px" />
            <Typography variant="p2"> {titleText}</Typography>
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Box display="flex" flexDirection="column" textAlign="left">
              <Typography variant="p3">
                {formatAddressString(addresses[0] || {})},
              </Typography>
              <Typography variant="p3">
                {formatCityStateZip(addresses[0] || {})}
              </Typography>
            </Box>
            {additionalField > 0 && (
              <Typography variant="p1">+{additionalField}</Typography>
            )}
          </Box>
          <TrackedLink
            sx={{
              color: 'midnightBlue.main',
              width: '100%',
            }}
            variant="c1"
            to={targetUrl}
          >
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
            >
              {linkText}
              <Box
                sx={{
                  transform: `scaleX(-1)`,
                }}
              >
                {' '}
                <OmLeftArrowFilledIcon width="24px" height="24px" />
              </Box>
            </Box>
          </TrackedLink>
        </Box>
      </Card>
    </>
  )
}

export default ActiveOrdersCard

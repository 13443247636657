import { useSnackbar } from '@flock/flock-component-library'
import {
  AnyInputConfig,
  CloseMobileIcon,
  GridForm,
  InputType,
} from '@flock/shared-ui'
import { Close } from '@mui/icons-material'
import {
  Modal,
  Paper,
  styled,
  Typography,
  IconButton,
  useTheme,
  useMediaQuery,
  Drawer,
  Box,
  Grid,
} from '@mui/material'
import React, { useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { useMutation } from '@apollo/client'
import {
  InvestorCreateOrUpdateLegalEntityBankAccountBasistheoryDocument,
  InvestorLegalEntityBankAccountDocument,
} from '@flock/flock-gql-server/src/__generated__/graphql'

const HeaderWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
})

const ModalWrapper = styled(Paper)({
  position: 'absolute',
  top: '10%',
  left: '50%',
  transform: 'translate(-50%)',
  width: '544px',
  maxHeight: '75vh',
  overflowY: 'auto',
  padding: '48px',
  borderRadius: '24px',
})

type FormInputs = {
  routingNumber: string
  accountNumber: string
  bankAccountIsPersonal: string
}

const inputConfigs: AnyInputConfig[] = [
  {
    name: 'routingNumber',
    type: InputType.Text,
    required: true,
    props: {
      label: 'Routing Number (Electronic Payment)',
      format: 'number',
      formatProps: { thousandSeparator: false, allowLeadingZeros: true },
      variant: 'outlined',
    },
  },
  {
    name: 'accountNumber',
    type: InputType.Text,
    required: true,
    gridItemProps: {
      paddingTop: '15px',
      sm: 6,
      paddingRight: '10px',
    },
    props: {
      label: 'Account Number',
      format: 'number',
      formatProps: { thousandSeparator: false, allowLeadingZeros: true },
      variant: 'outlined',
    },
  },
  {
    name: 'confirmAccountNumber',
    type: InputType.Text,
    required: true,
    gridItemProps: {
      paddingTop: '15px',
      sm: 6,
    },
    props: {
      label: 'Confirm Account Number',
      format: 'number',
      formatProps: { thousandSeparator: false, allowLeadingZeros: true },
      variant: 'outlined',
    },
  },
  {
    name: 'bankAccountIsPersonal',
    type: InputType.RadioSelect,
    required: true,
    gridItemProps: {
      paddingTop: '30px',
    },
    props: {
      label: 'Is this a personal or business account?',
      labelSize: 'small',
      row: true,
      options: [
        {
          label: 'Personal',
          value: 'true',
        },
        {
          label: 'Business',
          value: 'false',
        },
      ],
    },
  },
]

type ManualBankEntryModalProps = {
  selectedLegalEntityUuid: string
  open: boolean
  onClose: () => void
}

const ManualBankEntryModal = (props: ManualBankEntryModalProps) => {
  const { selectedLegalEntityUuid, open, onClose } = props
  const [loading, setLoading] = useState(false)

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const { notify } = useSnackbar()

  const [createBankAccount] = useMutation(
    InvestorCreateOrUpdateLegalEntityBankAccountBasistheoryDocument
  )

  const onSubmitForm = async (inputs: FormInputs) => {
    const { routingNumber, accountNumber, bankAccountIsPersonal } = inputs
    setLoading(true)
    try {
      await createBankAccount({
        variables: {
          input: {
            legalEntityUuid: selectedLegalEntityUuid,
            routingNumber,
            accountNumber,
            bankAccountIsPersonal: bankAccountIsPersonal === 'true',
          },
        },
        refetchQueries: [InvestorLegalEntityBankAccountDocument],
      })
      onClose()
      notify(`We've updated your bank account!`, 'success')
    } catch (e) {
      notify(
        'Failed to update your bank account details. Please contact us if the issue persists.',
        'error'
      )
    }
    setLoading(false)
  }

  const validationSchema = Yup.object().shape({
    routingNumber: Yup.string()
      .required('Routing Number is required')
      .min(9, 'Routing number should be 9 digits long')
      .max(9, 'Routing number should be 9 digits long'),
    accountNumber: Yup.string().required('Account Number is required'),
    confirmAccountNumber: Yup.string()
      .required('Confirm Account Number is required')
      .oneOf([Yup.ref('accountNumber')], 'Account numbers must match'),
  })

  return isMobile ? (
    <Drawer open={open} PaperProps={{ sx: { width: '100%' } }}>
      <Box position="fixed" top="8px" right="8px">
        <IconButton onClick={onClose}>
          <CloseMobileIcon />
        </IconButton>
      </Box>
      <Grid columns={4} spacing={2} pl="32px" pr="32px">
        <Box width="100%" display="flex" flexDirection="column" gap="40px">
          <HeaderWrapper
            sx={{ width: '100%', marginBottom: '1rem', pt: '64px' }}
          >
            <Typography variant="h3">Bank Account</Typography>
          </HeaderWrapper>
          <GridForm
            onSubmit={onSubmitForm}
            inputConfigs={inputConfigs}
            ctaBoxProps={{
              width: '114px',
              position: 'fixed',
              bottom: '40px',
              right: '32px',
            }}
            formProps={{
              resolver: yupResolver(validationSchema),
              mode: 'onChange',
            }}
            ctaText={loading ? '...' : 'Confirm'}
          />
        </Box>
      </Grid>
    </Drawer>
  ) : (
    <Modal open={open}>
      <ModalWrapper>
        <HeaderWrapper sx={{ marginBottom: '20px' }}>
          <Typography variant="h3">Bank Account</Typography>
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </HeaderWrapper>
        <GridForm
          onSubmit={onSubmitForm}
          inputConfigs={inputConfigs}
          formProps={{
            resolver: yupResolver(validationSchema),
            mode: 'onChange',
          }}
          ctaText={loading ? '...' : 'Confirm'}
        />
      </ModalWrapper>
    </Modal>
  )
}

export default ManualBankEntryModal

import { useMutation } from '@apollo/client'
import { useSnackbar, MOBILE_BREAKPOINT } from '@flock/flock-component-library'
import {
  InvestorCreateOrUpdateLegalEntityBankAccountDocument,
  InvestorLegalEntityBankAccountDocument,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import { AnyInputConfig, GridForm, InputType } from '@flock/shared-ui'
import {
  Box,
  Drawer,
  Grid,
  Modal,
  Paper,
  styled,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import React, { useState } from 'react'

const ModalWrapper = styled(Paper)({
  position: 'absolute',
  top: '10%',
  left: '50%',
  transform: 'translate(-50%)',
  width: '544px',
  maxHeight: '75vh',
  overflowY: 'auto',
  padding: '2rem',
  borderRadius: '24px',

  [MOBILE_BREAKPOINT]: {
    width: '100vw',
    height: '100vh',
    maxHeight: 'unset',
    top: 0,
    left: 0,
    transform: 'unset',
    padding: '1rem',
    boxSizing: 'border-box',
  },
})

const formInputConfigs: AnyInputConfig[] = [
  {
    name: 'bankAccountIsPersonal',
    type: InputType.RadioSelect,
    required: true,
    gridItemProps: {
      paddingTop: '30px',
    },
    props: {
      label: 'Is this a personal or business account?',
      labelSize: 'small',
      row: true,
      options: [
        {
          label: 'Personal',
          value: 'true',
        },
        {
          label: 'Business',
          value: 'false',
        },
      ],
    },
  },
]

type PersonalOrBusinessAccount = {
  bankAccountIsPersonal: string
}

type PersonalOrBusinessModalProps = {
  selectedLegalEntityUuid: string
  open: boolean
  onSubmit: () => void
  plaidAccountData: any
}

const PersonalOrBusinessModal = (props: PersonalOrBusinessModalProps) => {
  const { selectedLegalEntityUuid, open, onSubmit, plaidAccountData } = props

  const [loading, setLoading] = useState(false)
  const { notify } = useSnackbar()

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const [createOrUpdateLegalEntityBankAccount] = useMutation(
    InvestorCreateOrUpdateLegalEntityBankAccountDocument
  )

  const onSubmitForm = async (result: PersonalOrBusinessAccount) => {
    try {
      setLoading(true)
      const response = await createOrUpdateLegalEntityBankAccount({
        variables: {
          input: {
            legalEntityUuid: selectedLegalEntityUuid,
            ...plaidAccountData,
            bankAccountIsPersonal: result.bankAccountIsPersonal === 'true',
          },
        },
        refetchQueries: [InvestorLegalEntityBankAccountDocument],
      })
      const success: boolean =
        response?.data?.createOrUpdateLegalEntityBankAccount?.success || false

      if (!success) {
        throw new Error(
          'unsuccessful response from createOrUpdateLegalEntityBankAccount'
        )
      }

      onSubmit()
      notify("We've updated your bank account!", 'success')
    } catch (e) {
      notify(
        'We failed to update your bank account. Please try again and reach out if the error persists.',
        'error'
      )
    }
    setLoading(false)
  }

  return isMobile ? (
    <Drawer open={open} PaperProps={{ sx: { width: '100%' } }}>
      <Grid columns={4} spacing={2} pl="32px" pr="32px">
        <Box width="100%" display="flex" flexDirection="column" gap="40px">
          <GridForm
            onSubmit={onSubmitForm}
            inputConfigs={formInputConfigs}
            loading={loading}
            ctaText="Submit"
            ctaBoxProps={{
              width: '114px',
              position: 'fixed',
              bottom: '40px',
              right: '32px',
            }}
          />
        </Box>
      </Grid>
    </Drawer>
  ) : (
    <Modal open={open}>
      <ModalWrapper>
        <GridForm
          onSubmit={onSubmitForm}
          inputConfigs={formInputConfigs}
          loading={loading}
          ctaText="Submit"
        />
      </ModalWrapper>
    </Modal>
  )
}

export default PersonalOrBusinessModal

/* eslint no-restricted-globals: 0, prefer-destructuring: 0 */
import React, { useState } from 'react'
import { Router, Redirect, Location } from '@reach/router'
import { Alert, Box, CircularProgress, Typography } from '@mui/material'
import { useCookies } from 'react-cookie'
import { useFirebaseAuth } from '@flock/utils'
import {
  ACCOUNT_URL,
  ENABLE_SIMULATOR,
  LP_ONBOARDING_URL,
  ORDERS_URL,
  PERSONAL_INFORMATION_URL,
  PROPERTY_QUESTIONNAIRE_URL,
  ENTITY_INFORMATION_URL,
  TAX_INFORMATION_URL,
  FINANCIAL_INFORMATION_URL,
  SIMULATOR_URL,
} from '../constants'
import { InvestorAccountContextProvider } from '../components/InvestorAccountContext'
import InvestorDashboardPage from '../components/InvestorDashboardComponents/InvestorDashboardPage'
import PropertyQuestionnairePage from '../components/ExchangeComponents/OrderV3PageComponents/PropertyInformationComponents/PropertyQuestionnairePage'
import LpOnboardingPage from '../components/ExchangeComponents/OrderV3PageComponents/LpOnboardingComponents/LpOnboardingPage'
import PersonalInformationPage from '../components/ExchangeComponents/OrderV3PageComponents/PersonalInformationComponents/PersonalInformationPage'
import TaxInformationPage from '../components/ExchangeComponents/OrderV3PageComponents/TaxInformationComponents/TaxInformationPage'
import OrderV3Page from '../components/ExchangeComponents/OrderV3PageComponents/OrderV3Page'
import EntityInformationPage from '../components/ExchangeComponents/OrderV3PageComponents/EntityInformationComponents/EntityInformationPage'
import FinancialInformationPage from '../components/ExchangeComponents/OrderV3PageComponents/FinancialInformationComponents/FinancialInformationPage'
import TrackingWrapper from '../utils/TrackingWrapper'

type SimulatorProps = {
  children: React.ReactNode

  // eslint-disable-next-line react/no-unused-prop-types
  path: string
}

const Simulator = ({ children }: SimulatorProps) => <>{children}</>

const App = () => {
  const [isSimulator, setIsSimulator] = useState(false)
  const [cookies] = useCookies()

  const { user, useValidateLoggedInOrRedirect } = useFirebaseAuth()

  useValidateLoggedInOrRedirect()

  if (!user) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    )
  }

  return (
    <>
      {isSimulator && (
        <Alert
          sx={{
            backgroundColor: 'orange',
            position: 'sticky',
            top: 0,
            zIndex: 1000,
          }}
        >
          <Typography variant="p2">Investor Simulator</Typography>
        </Alert>
      )}
      <Location>
        {(locationProps) => {
          if (
            (location?.href.includes('simulator') || cookies.simulator) &&
            !isSimulator
          ) {
            setIsSimulator(true)
          }
          return (
            <InvestorAccountContextProvider location={locationProps.location}>
              <TrackingWrapper>
                <Box
                  sx={{
                    backgroundColor: 'gray1.main',
                    minHeight: '100vh',
                  }}
                >
                  <Router>
                    <InvestorDashboardPage path={ACCOUNT_URL} />
                    {/* <OrderPage path={`${ORDERS_URL}/:orderUuid`} /> */}
                    <OrderV3Page path={`${ORDERS_URL}/:orderUuid`} />
                    <PropertyQuestionnairePage
                      path={`${PROPERTY_QUESTIONNAIRE_URL}/:orderUuid/:propertyContributionUuid/:addressUuid/:taskUuid`}
                    />
                    <LpOnboardingPage path={`${LP_ONBOARDING_URL}`} />
                    <PersonalInformationPage
                      path={`${PERSONAL_INFORMATION_URL}/:orderUuid/:legalEntityUuid/:taskUuid`}
                    />
                    <EntityInformationPage
                      path={`${ENTITY_INFORMATION_URL}/:orderUuid/:legalEntityUuid/:taskUuid`}
                    />
                    <TaxInformationPage
                      path={`${TAX_INFORMATION_URL}/:orderUuid/:propertyContributionUuid/:taskUuid`}
                    />
                    <FinancialInformationPage
                      path={`${FINANCIAL_INFORMATION_URL}/:orderUuid/:taskUuid`}
                    />
                    {ENABLE_SIMULATOR && (
                      <Simulator path={`${SIMULATOR_URL}/:investorAccountUuid`}>
                        <InvestorDashboardPage path={ACCOUNT_URL} />
                        {/* <OrderPage path={`${ORDERS_URL}/:orderUuid`} /> */}
                        <PersonalInformationPage
                          path={`${PERSONAL_INFORMATION_URL}/:orderUuid/:legalEntityUuid/:taskUuid`}
                        />
                        <EntityInformationPage
                          path={`${ENTITY_INFORMATION_URL}/:orderUuid/:legalEntityUuid/:taskUuid`}
                        />
                        <TaxInformationPage
                          path={`${TAX_INFORMATION_URL}/:orderUuid/:propertyContributionUuid/:taskUuid`}
                        />
                        <FinancialInformationPage
                          path={`${FINANCIAL_INFORMATION_URL}/:orderUuid/:taskUuid`}
                        />

                        <PropertyQuestionnairePage
                          path={`${PROPERTY_QUESTIONNAIRE_URL}/:orderUuid/:propertyContributionUuid/:addressUuid/:taskUuid`}
                        />
                        <LpOnboardingPage path={`${LP_ONBOARDING_URL}`} />
                        <Redirect from="/*" to={ACCOUNT_URL} noThrow />
                      </Simulator>
                    )}
                    <Redirect from="/*" to={ACCOUNT_URL} noThrow />
                  </Router>
                </Box>
              </TrackingWrapper>
            </InvestorAccountContextProvider>
          )
        }}
      </Location>
    </>
  )
}
export default App

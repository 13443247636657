import React from 'react'
import { Box, Typography, Grid, Paper, Skeleton } from '@mui/material'
import { OmSymbolLogo } from '@flock/shared-ui'

import useAccountValueDisplay from './useAccountValueDisplay'
import { AccountValueDisplayProps } from './accountValueDisplayTypes'

const AccountValueDisplay = (props: AccountValueDisplayProps) => {
  const { loading, totalEquityValueString } = useAccountValueDisplay(props)

  return (
    <Grid item xs={12}>
      <Paper
        sx={{
          padding: '16px',
          px: '24px',
          borderRadius: '24px',
        }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          width="100%"
          height="100%"
          alignItems="center"
        >
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            width="100%"
            height="100%"
          >
            {loading ? (
              <Skeleton width="25%" height="32px" />
            ) : (
              <Typography variant="p3" paddingBottom="8px">
                Your total equity value
              </Typography>
            )}
            <Typography
              variant="h3"
              sx={{ fontSize: '32px', fontWeight: 500 }}
              fontFamily="Outfit"
              data-cy="totalEquityValue"
            >
              {loading ? (
                <Skeleton width="85%" height="32px" />
              ) : (
                <>{totalEquityValueString}</>
              )}
            </Typography>
          </Box>
          <OmSymbolLogo width="80px" height="80px" />
        </Box>
      </Paper>
    </Grid>
  )
}

export default AccountValueDisplay

import React, { useState } from 'react'
import {
  Modal,
  styled,
  Typography,
  CircularProgress,
  IconButton,
  Drawer,
  Grid,
  Box,
} from '@mui/material'
import { CloseMobileIcon, TrackedLink } from '@flock/shared-ui'
import theme from '@flock/shared-ui/src/theme/theme'
import ContactInfoSection from './ContactInfo/ContactInfoSection'
import { useInvestorAccountContext } from '../../InvestorAccountContext'
import AuditLogModal from './AuditLog/AuditLog'

const LoadingWrapper = styled('div')({
  position: 'absolute',
  top: '25%',
  left: '50%',
  transform: 'translate(-50%)',
})

const StyledCircularProgress = styled(CircularProgress)({
  color: theme.palette.trustBlue.main,
})

const SettingsGroupHeaderWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
})

type InvestorAccountSettingsMobileDrawerProps = {
  open: boolean
  onClose: () => void
}

const InvestorAccountSettingsMobileDrawer = (
  props: InvestorAccountSettingsMobileDrawerProps
) => {
  const { open, onClose } = props
  const { investorContextLoading, investorAccountUuidOverride } =
    useInvestorAccountContext()
  const [contactInfoModalOpen, setContactInfoModalOpen] = useState(false)
  const [auditLogModalOpen, setAuditLogModalOpen] = useState(false)

  const anyLoading = investorContextLoading

  if (anyLoading) {
    return (
      <Modal open={open}>
        <LoadingWrapper>
          <StyledCircularProgress size="2rem" />
        </LoadingWrapper>
      </Modal>
    )
  }
  return (
    <Drawer
      disableEnforceFocus
      open={open}
      PaperProps={{
        sx: {
          width: '100%',
          display: contactInfoModalOpen ? 'none' : 'inherit',
        },
      }}
    >
      <Box position="absolute" top="8px" right="8px">
        <IconButton onClick={onClose}>
          <CloseMobileIcon />
        </IconButton>
      </Box>
      <Grid columns={4} spacing={2} pl="32px" pr="32px" pb="32px">
        <Box width="100%" display="flex" flexDirection="column" gap="40px">
          <SettingsGroupHeaderWrapper
            sx={{ width: '100%', marginBottom: '1rem', pt: '64px' }}
          >
            <Typography variant="h3">Investor Settings</Typography>
          </SettingsGroupHeaderWrapper>
        </Box>
        <ContactInfoSection setOuterHidden={setContactInfoModalOpen} />
        <Typography variant="p3" color="gray6.main">
          To change bank account settings, go to Distribution Settings, and
          select the entity you want to change.
        </Typography>
        <br />
        <br />
        <Typography>
          <TrackedLink onClick={() => setAuditLogModalOpen(true)}>
            View Activity Log
          </TrackedLink>
        </Typography>
        <AuditLogModal
          open={auditLogModalOpen}
          entityUuid={investorAccountUuidOverride}
          entityIsInvestor
          onClose={() => setAuditLogModalOpen(false)}
        />
      </Grid>
    </Drawer>
  )
}

export default InvestorAccountSettingsMobileDrawer

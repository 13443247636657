// export the data from the calculation engine. This should be one of the legal entities data, or the cumulative sum. It should also export the selected legal entity among other things like callbacks for the accountOverTime to historical performance chart

import { gql, useQuery } from '@apollo/client'
import {
  Core_LegalEntity,
  Core_QuarterlyInvestmentTransactionSummary,
  InvestorAccountSectionGetTransactionHistoryForLegalEntityByQuarterDocument,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import { sessionStore } from '@flock/utils'
import { useCallback, useEffect, useState } from 'react'
import { flatten } from 'lodash'
import theme from '@flock/shared-ui/src/theme/theme'
import { useMediaQuery } from '@mui/material'
import { useInvestorAccountContext } from '../../InvestorAccountContext'
import {
  QuarterData,
  AccountSectionData,
  ChartPeriodSelection,
  SelectedLegalEntityAggregateTransactionData,
  TaxData,
} from './accountSectionTypes'
import { quarterStringToDate } from '../../../utils'
import { INVESTOR_DASHBOARD_GET_LEGAL_ENTITY_TAX_SUMMARY } from '../Home/graphql/queries'

const getMonthsBetweenDates = (startDate: Date, endDate: Date) => {
  let months
  months = (endDate.getFullYear() - startDate.getFullYear()) * 12
  months -= startDate.getMonth()
  months += endDate.getMonth()
  return months <= 0 ? 0 : months
}

// network layer
// This is used by Overmoon
export const GET_INVESTMENT_TRANSACTION_INFO_FOR_LEGAL_ENTITY_REQUEST = gql`
  query InvestorAccountSectionGetInvestmentTransactionInfoForLegalEntity(
    $input: Core_GetInvestmentTransactionInfoForLegalEntityRequestInput!
  ) {
    getInvestmentTransactionInfoForLegalEntity(input: $input) {
      investmentTransactionInfo {
        effectiveDate
        transactionType
        uuid
        shareCountChange
        equityValueChangeCents
        isPublished
      }
    }
  }
`

export const GET_TRANSACTION_HISTORY_FOR_LEGAL_ENTITY_BY_QUARTER = gql`
  query InvestorAccountSectionGetTransactionHistoryForLegalEntityByQuarter(
    $input: Core_GetInvestmentTransactionInfoForLegalEntityByQuarterRequestInput!
  ) {
    getInvestmentTransactionInfoForLegalEntityByQuarter(input: $input) {
      quarterlyInvestmentTransactionSummaries {
        quarterString
        startingEquityValueCents
        redemptionCents
        redemptionShareCountChange
        shareAppreciationCents
        quarterEndProcessCreatedAtDate
        quarterSharePriceCents
        shareCount
        quarterlyContributions {
          contributedValueCents
          contributionDate
        }
        quarterlyTransfers {
          transferredDate
          transferredValueCents
        }
      }
    }
  }
`

const getDateFromQuarterData = (data: QuarterData) => {
  let date = new Date()
  if (data.quarterString) {
    const quarter = data.quarterString.split(' ')[0]
    const year = data.quarterString.split(' ')[1]
    switch (quarter) {
      case 'Q1':
        date = new Date(`march, 2, ${year}`)
        break
      case 'Q2':
        date = new Date(`june, 2, ${year}`)
        break
      case 'Q3':
        date = new Date(`september, 2, ${year}`)
        break
      case 'Q4':
        date = new Date(`december, 2, ${year}`)
        break
      default:
        break
    }
  } else if (data.monthString) {
    const month = data.monthString.split(' ')[0]
    const year = data.monthString.split(' ')[1]
    date = new Date(`${month}, 1, ${year}`)
  }

  return date
}

const combineQuarterlyData = (data: QuarterData[]) => {
  const combinedData: QuarterData[] = []
  data.forEach((quarterData) => {
    const key = quarterData.quarterString || quarterData.monthString
    const existingData = combinedData.find((combinedQuarterData) => {
      if (combinedQuarterData.quarterString) {
        return combinedQuarterData.quarterString === key
      } else {
        return combinedQuarterData.monthString === key
      }
    })
    if (existingData) {
      existingData.startingAccountValue += quarterData.startingAccountValue
      existingData.endingAccountValue += quarterData.endingAccountValue
      existingData.fundValueChange += quarterData.fundValueChange
      existingData.redemptions += quarterData.redemptions
      existingData.cashYield += quarterData.cashYield
      existingData.quarterSharePrice = quarterData.quarterSharePrice
      existingData.shareCount += quarterData.shareCount
      existingData.hasContributionInQuarter =
        quarterData.hasContributionInQuarter
    } else {
      combinedData.push(quarterData)
    }
  })
  return combinedData
}

const mapQuarterlyTransactionSummaryToQuarterData = (
  data: Core_QuarterlyInvestmentTransactionSummary | null
) => {
  if (!data) {
    return []
  }
  const tempParsedQuarterlyData: QuarterData[] = []
  let startingValue = data?.startingEquityValueCents || 0
  if (data?.quarterlyContributions?.length! > 0) {
    data?.quarterlyContributions?.forEach((contribution) => {
      const contributionDate = new Date(contribution.contributionDate)
      const contributionMonth = contributionDate.toLocaleString('default', {
        month: 'long',
      })
      const contributionYear = contributionDate.getFullYear()
      tempParsedQuarterlyData.push({
        monthString: `${contributionMonth} ${contributionYear}`,
        startingAccountValue: startingValue,
        endingAccountValue:
          data?.startingEquityValueCents + contribution.contributedValueCents,
        fundValueChange: 0,
        redemptions: 0,
        cashYield: 0,
        quarterSharePrice: data?.quarterSharePriceCents || 0, // contribution month should have share price
        shareCount: data?.shareCount || 0,
        hasContributionInQuarter:
          (data?.quarterlyContributions?.length || 0) > 0,
      })

      startingValue += contribution.contributedValueCents
    })
  }

  if (data?.quarterlyTransfers?.length! > 0) {
    data?.quarterlyTransfers?.forEach((transfer) => {
      const transferDate = new Date(transfer.transferredDate)

      const transferMonth = transferDate.toLocaleString('default', {
        month: 'long',
      })
      const transferYear = transferDate.getFullYear()

      const transferAmount = transfer.transferredValueCents

      tempParsedQuarterlyData.push({
        monthString: `${transferMonth} ${transferYear}`,
        startingAccountValue: startingValue,
        endingAccountValue: data?.startingEquityValueCents + transferAmount,
        fundValueChange: 0,
        redemptions: 0,
        cashYield: 0,
        quarterSharePrice: data?.quarterSharePriceCents || 0, // contribution month should have share price
        shareCount: data?.shareCount || 0,
        hasContributionInQuarter: (data?.quarterlyTransfers?.length || 0) > 0,
      })
      startingValue += transferAmount
    })
  }

  const endingValue =
    startingValue +
    (data?.shareAppreciationCents || 0) -
    (data?.redemptionCents || 0)

  const quarter = data.quarterString.split(' ')[1]
  const year = data.quarterString.split(' ')[0]
  const annualizedCashYield = (data?.redemptionCents / endingValue) * 4 // multiply by 4 to annualize

  tempParsedQuarterlyData.push({
    quarterString: `${quarter} ${year}`,
    startingAccountValue: startingValue,
    endingAccountValue: endingValue,
    fundValueChange: data?.shareAppreciationCents - data?.redemptionCents || 0,
    redemptions: data?.redemptionCents || 0,
    cashYield: data?.quarterString ? annualizedCashYield : 0, // contribution month shouldn't have yield
    quarterSharePrice: data?.quarterSharePriceCents || 0,
    shareCount: data?.shareCount || 0,
    hasContributionInQuarter: (data?.quarterlyContributions?.length || 0) > 0,
  })

  return tempParsedQuarterlyData
}

// iterate backwards thru quarter data to find the first date that is less than today. Default to today if no date is found
const getEffectiveDateHelper = (quarterData: QuarterData[]) => {
  let effectiveDate = new Date() // default to current date
  quarterData
    .slice()
    .reverse()
    .every((currQuarterData) => {
      const quarterDataDate = getDateFromQuarterData(currQuarterData)
      if (quarterDataDate.getTime() < new Date().getTime()) {
        if (currQuarterData?.quarterString) {
          effectiveDate = quarterStringToDate(currQuarterData?.quarterString)
        } else if (currQuarterData?.monthString) {
          effectiveDate = quarterDataDate
        }
        return false
      }

      return true
    })

  return `${effectiveDate.toLocaleString('default', {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
  })}`
}
export const useAccountSection = (): AccountSectionData => {
  const [quarterlyData, setQuarterlyData] = useState<QuarterData[][]>([])
  const [aggregateTransactionData, setAggregateTransactionData] = useState<
    SelectedLegalEntityAggregateTransactionData[]
  >([])
  const [
    selectedLegalEntityAggregateTransactionData,
    setSelectedLegalEntityAggregateTransactionData,
  ] = useState<SelectedLegalEntityAggregateTransactionData>({
    totalContribution: 0,
    totalEquityValue: 0,
    totalFundValueChange: 0,
    totalRedemptions: 0,
    totalCashYield: 0,
    currentSharePrice: 0,
    currentShareCount: 0,
  })
  const [
    selectedLegalEntityQuarterlyData,
    setSelectedLegalEntityQuarterlyData,
  ] = useState<QuarterData[]>()
  const [selectedLegalEntityTaxData, setSelectedLegalEntityTaxData] =
    useState<TaxData>({
      totalDepreciationCents: 0,
      totalTaxableIncomeCents: 0,
      currentCostBasisCents: 0,
      totalEmbeddedGainCents: 0,
    })
  const [legalEntityTaxData, setLegalEntityTaxData] = useState<TaxData[]>([])
  const [selectedLegalEntity, setSelectedLegalEntity] =
    useState<Core_LegalEntity>()
  const [hoveredQuarterLabel, setHoveredQuarterLabel] = useState<
    string | undefined
  >()
  const [periodSelection, setPeriodSelection] =
    useState<ChartPeriodSelection>('1Y')
  const [computing, setComputing] = useState(true)
  // context hooks
  const { legalEntities } = useInvestorAccountContext()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'))

  // we can't use `useLazyQuery` here because of this bug https://github.com/apollographql/apollo-client/issues/9755
  // tldr: on v3.6, useLazyQuery will overwrite the previous query result with the new one
  const {
    refetch: refetchEquityInfoTimeseries,
    loading,
    error,
  } = useQuery(
    InvestorAccountSectionGetTransactionHistoryForLegalEntityByQuarterDocument,
    {
      skip: true,
      notifyOnNetworkStatusChange: true,
    }
  )

  const {
    refetch: refetchLegalEntityTaxSummary,
    loading: getLegalEntityTaxSummaryLoading,
    error: getLegalEntityTaxSummaryError,
  } = useQuery(INVESTOR_DASHBOARD_GET_LEGAL_ENTITY_TAX_SUMMARY, {
    skip: true,
    notifyOnNetworkStatusChange: true,
  })

  const getQuarterlyDataHelper = useCallback(async () => {
    setComputing(true)
    const parsedQuarterlyData: QuarterData[][] = []
    const taxDataArr: TaxData[] = []
    if (legalEntities[0].uuid === 'aggregate') {
      const timeSeriesQueries: any[] = []
      const taxDataQueries: any[] = []
      legalEntities.slice(1).forEach((legalEntity) => {
        timeSeriesQueries.push(
          refetchEquityInfoTimeseries({
            input: {
              legalEntityUuid: legalEntity.uuid,
              includeStaged: sessionStore.getItem('simulator') !== null,
            },
          })
        )
        taxDataQueries.push(
          refetchLegalEntityTaxSummary({
            input: {
              legalEntityUuid: legalEntity.uuid,
            },
          })
        )
      })

      const timeSeriesQueryResults = await Promise.all(timeSeriesQueries)
      timeSeriesQueryResults.forEach((data) => {
        const currQuarterData: QuarterData[] = []
        data?.data.getInvestmentTransactionInfoForLegalEntityByQuarter?.quarterlyInvestmentTransactionSummaries?.forEach(
          (quarterData: Core_QuarterlyInvestmentTransactionSummary | null) => {
            currQuarterData.push(
              ...mapQuarterlyTransactionSummaryToQuarterData(quarterData)
            )
          }
        )
        parsedQuarterlyData.push(currQuarterData)
      })
      const taxDataQueryResults = await Promise.all(taxDataQueries)
      const aggregateTaxData: TaxData = {
        currentCostBasisCents: 0,
        totalDepreciationCents: 0,
        totalTaxableIncomeCents: 0,
        totalEmbeddedGainCents: 0,
      }
      taxDataQueryResults.forEach((data) => {
        const taxData: TaxData = {
          currentCostBasisCents: 0,
          totalDepreciationCents: 0,
          totalTaxableIncomeCents: 0,
          totalEmbeddedGainCents: 0,
        }
        if (data?.data?.getLegalEntityTaxSummary) {
          taxData.currentCostBasisCents =
            data?.data?.getLegalEntityTaxSummary?.currentCostBasisCents
          taxData.totalDepreciationCents =
            data?.data?.getLegalEntityTaxSummary?.totalDepreciationCents
          taxData.totalTaxableIncomeCents =
            data?.data?.getLegalEntityTaxSummary?.totalTaxableIncomeCents
          taxData.totalEmbeddedGainCents =
            data?.data?.getLegalEntityTaxSummary?.totalEmbeddedGainCents
          aggregateTaxData.currentCostBasisCents +=
            taxData.currentCostBasisCents
          aggregateTaxData.totalDepreciationCents +=
            taxData.totalDepreciationCents
          aggregateTaxData.totalTaxableIncomeCents +=
            taxData.totalTaxableIncomeCents
          aggregateTaxData.totalEmbeddedGainCents +=
            taxData.totalEmbeddedGainCents
          taxDataArr.push(taxData)
        }
      })
      taxDataArr.unshift(aggregateTaxData)
    } else {
      const { data } = await refetchEquityInfoTimeseries({
        input: {
          legalEntityUuid: legalEntities[0]?.uuid,
          includeStaged: sessionStore.getItem('simulator') !== null,
        },
      })
      const { data: taxData } = await refetchLegalEntityTaxSummary({
        input: {
          legalEntityUuid: legalEntities[0]?.uuid,
        },
      })
      if (taxData?.getLegalEntityTaxSummary) {
        const selectedTaxData: TaxData = {
          currentCostBasisCents:
            taxData?.getLegalEntityTaxSummary?.currentCostBasisCents,
          totalDepreciationCents:
            taxData?.getLegalEntityTaxSummary?.totalDepreciationCents,
          totalTaxableIncomeCents:
            taxData?.getLegalEntityTaxSummary?.totalTaxableIncomeCents,
          totalEmbeddedGainCents:
            taxData?.getLegalEntityTaxSummary?.totalEmbeddedGainCents,
        }
        setSelectedLegalEntityTaxData(selectedTaxData)
      }

      if (
        data?.getInvestmentTransactionInfoForLegalEntityByQuarter
          ?.quarterlyInvestmentTransactionSummaries
      ) {
        const currQuarterData: QuarterData[] = []
        data?.getInvestmentTransactionInfoForLegalEntityByQuarter?.quarterlyInvestmentTransactionSummaries?.forEach(
          (quarterData) => {
            currQuarterData.push(
              ...mapQuarterlyTransactionSummaryToQuarterData(quarterData)
            )
          }
        )
        parsedQuarterlyData.push(currQuarterData)
      }
    }

    const combinedQuarterlyData: QuarterData[] = JSON.parse(
      JSON.stringify(flatten(parsedQuarterlyData))
    ) // make deep copy

    combinedQuarterlyData.sort((a, b) => {
      const aDate = getDateFromQuarterData(a)
      const bDate = getDateFromQuarterData(b)
      return aDate.getTime() - bDate.getTime()
    })

    parsedQuarterlyData.unshift(combineQuarterlyData(combinedQuarterlyData))

    // get aggregate data from parsedQuarterlyData
    const parsedAggregateTransactionData: SelectedLegalEntityAggregateTransactionData[] =
      []

    parsedQuarterlyData.forEach((quarterData) => {
      const aggregateSelectedLegalEntityTransactionData = {
        totalContribution: 0,
        totalEquityValue: 0,
        totalFundValueChange: 0,
        totalRedemptions: 0,
        totalCashYield: 0,
        currentSharePrice: 0,
        currentShareCount: 0,
      }
      quarterData.forEach((data, index) => {
        // we have the ending account value check so preserve the return value in the case of if someone fully transfers out
        if (
          data.monthString !== undefined &&
          data.monthString !== '' &&
          data.endingAccountValue !== 0
        ) {
          aggregateSelectedLegalEntityTransactionData.totalContribution +=
            data.endingAccountValue - data.startingAccountValue
        }
        aggregateSelectedLegalEntityTransactionData.totalFundValueChange +=
          data.fundValueChange
        aggregateSelectedLegalEntityTransactionData.totalRedemptions +=
          data.redemptions
        aggregateSelectedLegalEntityTransactionData.currentShareCount =
          data.shareCount
        if (index === quarterData.length - 1) {
          aggregateSelectedLegalEntityTransactionData.totalEquityValue =
            data.endingAccountValue
          aggregateSelectedLegalEntityTransactionData.currentSharePrice =
            data.quarterSharePrice
        }
      })

      // TEMPORARY CALCULATION FOR TOTAL CASH YIELD
      aggregateSelectedLegalEntityTransactionData.totalCashYield =
        aggregateSelectedLegalEntityTransactionData.totalRedemptions /
        aggregateSelectedLegalEntityTransactionData.totalEquityValue

      // append to aggregateTransactionData
      parsedAggregateTransactionData.push(
        aggregateSelectedLegalEntityTransactionData
      )
    })

    if (taxDataArr.length > 1) {
      setSelectedLegalEntityTaxData(taxDataArr[0]) // first index is aggregate tax data
    }
    setAggregateTransactionData(parsedAggregateTransactionData)
    setSelectedLegalEntityAggregateTransactionData(
      parsedAggregateTransactionData[0]
    )

    setQuarterlyData(parsedQuarterlyData)
    setLegalEntityTaxData(taxDataArr)
    setSelectedLegalEntity(legalEntities[0])
    setSelectedLegalEntityQuarterlyData(parsedQuarterlyData[0])
    setComputing(false)
  }, [legalEntities, refetchEquityInfoTimeseries, refetchLegalEntityTaxSummary])

  useEffect(() => {
    getQuarterlyDataHelper()
  }, [getQuarterlyDataHelper])

  const setSelectedLegalEntityHelper = (uuid: string) => {
    const index = legalEntities.findIndex((entity) => entity.uuid === uuid)
    setSelectedLegalEntity(legalEntities[index])
    setSelectedLegalEntityQuarterlyData(quarterlyData[index])
    setSelectedLegalEntityTaxData(legalEntityTaxData[index])
    setSelectedLegalEntityAggregateTransactionData(
      aggregateTransactionData[index]
    )
  }

  let joinedDate = new Date()
  if (!selectedLegalEntity?.dateJoined) {
    legalEntities.forEach((legalEntity) => {
      const leJoinedDate = new Date(legalEntity.dateJoined)
      if (leJoinedDate.getTime() < joinedDate.getTime()) {
        joinedDate = leJoinedDate
      }
    })
  } else {
    joinedDate = new Date(selectedLegalEntity?.dateJoined)
  }
  const durationInFund = `${getMonthsBetweenDates(
    joinedDate,
    new Date()
  )} months`

  return {
    loading: loading || computing || getLegalEntityTaxSummaryLoading,
    error: error || getLegalEntityTaxSummaryError,

    legalEntities,
    selectedLegalEntity: selectedLegalEntity || ({} as Core_LegalEntity),
    setSelectedLegalEntityByUuid: setSelectedLegalEntityHelper,
    selectedLegalEntityQuarterlyTransactionData:
      selectedLegalEntityQuarterlyData || [],
    legalEntityQuarterlyTransactionData: quarterlyData,
    selectedLegalEntityTaxData,
    effectiveDate: getEffectiveDateHelper(
      selectedLegalEntityQuarterlyData || []
    ),
    selectedLegalEntityAggregateTransactionData,

    hoveredQuarterLabel,
    setHoveredQuarterLabel,
    periodSelection,
    setPeriodSelection,

    durationInFund,

    isMobile,
    isTablet,
  }
}

// for mobile, tablet, desktop, use grid layout change rather than separate files

import { PillSelector } from '@flock/shared-ui'
import { Box, Grid, Typography } from '@mui/material'
import React from 'react'
import {
  formatNumberWithCommas,
  OM_DEFAULT_PRICE_PER_SHARE,
} from '@flock/utils'
import SectionLayout from '../../SharedComponents/SectionLayout'
import EquityDetail from './EquityDetail'
import { useAccountSection } from './useAccountSection'
import AccountValueDisplay from './AccountValueDisplay/AccountValueDisplay'

type AccountSectionProps = {
  id: string
}

const AccountSection = (props: AccountSectionProps) => {
  const {
    isMobile,
    isTablet,
    legalEntityQuarterlyTransactionData,
    selectedLegalEntity,
    selectedLegalEntityAggregateTransactionData,
    legalEntities,
    effectiveDate,
    setSelectedLegalEntityByUuid,
    durationInFund,
    loading,
  } = useAccountSection()
  let numColumns = 9
  if (isMobile) {
    numColumns = 4
  } else if (isTablet) {
    numColumns = 6
  }

  const { id } = props

  /**
   * Presentational layer
   * Contains the presentational pieces of the UI. This area should be as
   * simple as possible and any logic should be delegated to the logic hook.
   * Use separate components for the mobile, tablet, and desktop views to avoid
   * excessive ternaries.
   */

  return (
    <SectionLayout name="account-section" columns={numColumns} id={id}>
      <Grid item xs={numColumns}>
        <Box
          alignItems="baseline"
          display="flex"
          gap="8px"
          width="100%"
          justifyContent="space-between"
        >
          <Typography variant="h3">My Account</Typography>
          {!loading && (
            <Typography variant="p3" color="gray5.main">
              {`Last updated ${effectiveDate}`}
            </Typography>
          )}
        </Box>
      </Grid>
      {!loading && legalEntities.length > 1 && (
        <Grid item xs={numColumns}>
          <PillSelector
            options={legalEntities.map((entity, idx) => ({
              label: entity.name || '',
              value: entity.uuid,
              disabled: legalEntityQuarterlyTransactionData[idx]?.length === 0,
            }))}
            selectedValue={selectedLegalEntity.uuid}
            onSelect={setSelectedLegalEntityByUuid}
          />
        </Grid>
      )}
      <Grid item md={12}>
        <Grid container columns={12} spacing={isMobile ? 2 : 4}>
          <Grid item xs={12}>
            <AccountValueDisplay
              loading={loading}
              totalEquityValue={
                selectedLegalEntityAggregateTransactionData.totalEquityValue
              }
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <EquityDetail
              amount={formatNumberWithCommas(
                selectedLegalEntityAggregateTransactionData.totalEquityValue /
                  OM_DEFAULT_PRICE_PER_SHARE,
                2
              )}
              description="share count"
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <EquityDetail
              amount={`$${formatNumberWithCommas(OM_DEFAULT_PRICE_PER_SHARE)}`}
              description="price per share"
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <EquityDetail
              amount="4.0%"
              description="target distribution"
              dataCy="netAccountPerformance"
              annualized
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <EquityDetail
              amount={durationInFund}
              description="duration in Fund"
              loading={loading}
              oneLine
            />
          </Grid>
        </Grid>
      </Grid>
    </SectionLayout>
  )
}

export default AccountSection

import {
  Box,
  Paper,
  Skeleton,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import React from 'react'

type EquityDetailProps = {
  amount: string
  description: React.ReactNode
  color?: string
  loading?: boolean
  oneLine?: boolean
  dataCy?: string
  annualized?: boolean
}

const EquityDetail = (props: EquityDetailProps) => {
  const { amount, annualized, description, color, loading, oneLine, dataCy } =
    props
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  let justifyContent = ''
  if (isMobile) {
    justifyContent = 'space-between'
  } else if (oneLine) {
    justifyContent = 'inherit'
  } else {
    justifyContent = 'center'
  }
  return (
    <Paper
      sx={{
        py: '18px',
        px: '12px',
        borderRadius: '16px',
        boxSizing: 'border-box',
        width: '100%',
        height: '100%',
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent={justifyContent}
        alignItems="center"
        gap="4px"
        height="100%"
        width="100%"
      >
        {loading ? (
          <Skeleton width="100%" height="32px" />
        ) : (
          <Box>
            <Typography
              display="inline-block"
              fontSize={isMobile ? '20px' : '16px'}
              lineHeight={isMobile ? '16px' : '32px'}
              sx={{ fontWeight: 500, textAlign: 'center', color }}
              data-cy={dataCy}
            >
              {amount}
            </Typography>
            {annualized && (
              <Typography
                variant="c1"
                display="inline-block"
                sx={{ fontSize: '8px', fontWeight: 500 }}
              >
                &nbsp;annualized
              </Typography>
            )}
          </Box>
        )}
        <Box
          display="flex"
          flexDirection={isMobile ? 'row' : 'column'}
          justifyContent={isMobile ? 'space-between' : 'center'}
          alignItems="center"
        >
          <Typography
            variant="c1"
            sx={{
              fontSize: '14px',
              lineHeight: '20px',
              fontWeight: 400,
              textAlign: 'center',
            }}
          >
            {description}
          </Typography>
        </Box>
      </Box>
    </Paper>
  )
}

EquityDetail.defaultProps = {
  color: 'gray8.main',
  loading: undefined,
  oneLine: false,
  dataCy: '',
  annualized: false,
}

export default EquityDetail

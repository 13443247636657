import {
  Grid,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useSnackbar } from '@flock/flock-component-library'
import { useMutation, useQuery } from '@apollo/client'
import Fade from 'react-reveal/Fade'
import { sessionStore } from '@flock/utils'
import {
  Core_LegalEntityDistributionPreferences,
  Core_LegalEntityUpdate,
  InvestorDashboardGetEquityInfoTimeseriesForInvestorDocument,
  InvestorDashboardGetInvestorUpdatesDocument,
  InvestorEditLegalEntityMutationDocument,
  InvestorGetDistributionPreferencesDocument,
  InvestorGetLegalEntitiesByInvestorAccountUuidDocument,
  InvestorUpdateDistributionPreferencesDocument,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import SectionLayout from '../../SharedComponents/SectionLayout'
import HomeMessageCenter from './HomeMessageCenter'
import PreferencesModal from '../Settings/FlexibleDistributions/PreferencesModal'
import ActiveOrdersCard from './ActiveOrderCard'
import PreferencesMobileDrawer from '../Settings/FlexibleDistributions/PreferencesMobileDrawer'
import HomeMessageCenterMobile from './HomeMessageCenterMobile'
import { useInvestorAccountContext } from '../../InvestorAccountContext'

type HomeSectionProps = {
  id: string
  messageCenterMobileOpen?: boolean
  setMessageCenterMobileOpen?: (open: boolean) => void
}

const StyledFade = styled(Fade)({
  '& .react-reveal': {
    display: 'inline-block',
  },
})

const HomeSection = (props: HomeSectionProps) => {
  const { id, messageCenterMobileOpen, setMessageCenterMobileOpen } = props
  const { notify } = useSnackbar()
  const [showOrders, setShowOrders] = useState(false)

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isTablet = useMediaQuery(theme.breakpoints.down('md'))

  let numColumns = 9
  if (isMobile) {
    numColumns = 4
  } else if (isTablet) {
    numColumns = 6
  } else {
    numColumns = 9
  }

  const {
    investorFirstName,
    selectedLegalEntity: legalEntityData,
    investorContextLoading,
    investorAccountUuidOverride,
    legalEntities,
    activeOrderStatuses,
  } = useInvestorAccountContext()

  const [updates, setUpdates] = useState<Core_LegalEntityUpdate[]>([])

  // as for why this is -1, ask rishov dutta
  const [totalCashTaken, setTotalCashTaken] = useState(-1)

  const multipleLegalEntities = legalEntities?.length > 1
  const legalEntity = legalEntityData

  const {
    data: distributionPreferencesData,
    loading: distributionPreferencesLoading,
  } = useQuery(InvestorGetDistributionPreferencesDocument, {
    variables: {
      input: {
        legalEntityUuid: legalEntity?.uuid,
      },
    },
    skip: !legalEntity?.uuid || multipleLegalEntities,
  })

  const { loading: updatesLoading, data: updatesData } = useQuery(
    InvestorDashboardGetInvestorUpdatesDocument,
    {
      variables: {
        input: {
          investorUuid: investorAccountUuidOverride,
        },
      },
      skip: !investorAccountUuidOverride || updates.length > 0,
    }
  )

  useEffect(() => {
    if (updatesData) {
      setUpdates(
        updatesData?.getUpdates?.legalEntityUpdates as Core_LegalEntityUpdate[]
      )
    }
  }, [updatesData])

  const { loading: equityInfoTimeSeriesLoading, data: equityInfoTimeseries } =
    useQuery(InvestorDashboardGetEquityInfoTimeseriesForInvestorDocument, {
      variables: {
        input: {
          investorUuid: investorAccountUuidOverride,
          includeStaged: sessionStore.getItem('simulator') !== null,
        },
      },
      skip: !investorAccountUuidOverride || totalCashTaken !== -1,
    })

  const equityTimeseriesArray =
    equityInfoTimeseries?.getEquityInfoTimeseriesForInvestor
      ?.aggregateEquityInfo
  if (equityTimeseriesArray?.length! > 0 && totalCashTaken === -1) {
    const mostRecentData =
      equityTimeseriesArray![equityTimeseriesArray?.length! - 1]
    const redemptions = mostRecentData!.aggregateRedemptions! / 100
    setTotalCashTaken(-redemptions)
  }

  const distributionPreferences =
    distributionPreferencesData?.getDistributionPreferences
      ?.distributionPreferences
  const legalEntityUuid = legalEntity?.uuid

  const [editLegalEntity] = useMutation(InvestorEditLegalEntityMutationDocument)
  const [updateDistributionPreferences] = useMutation(
    InvestorUpdateDistributionPreferencesDocument
  )

  const disableMicroredemptions = !distributionPreferences?.legalEntityUuid
  let reinvestmentPercent = 100 - legalEntity?.flexibleDistributionPercent!

  const [showPreferencesModal, setShowPreferencesModal] = useState(false)
  const [showPreferencesMobileDrawer, setShowPreferencesMobileDrawer] =
    useState(false)
  const confirmDistributions = async (
    newCashDistributionPercentage: number
  ) => {
    let cashDistributionEnabled = false
    if (newCashDistributionPercentage > 0) {
      // Enable cash distribution
      cashDistributionEnabled = true
    }
    reinvestmentPercent = 100 - newCashDistributionPercentage

    try {
      await editLegalEntity({
        variables: {
          editLegalEntityInput: {
            uuid: legalEntity?.uuid || legalEntityUuid,
            cashDistributionEnabled,
            flexibleDistributionPercent: newCashDistributionPercentage,
          },
        },
        refetchQueries: [InvestorGetLegalEntitiesByInvestorAccountUuidDocument],
      })

      if (!disableMicroredemptions) {
        const cashDistributionCents =
          (newCashDistributionPercentage / 100) *
          (distributionPreferences?.distributionAllowedCents as number)

        const microredemptionAmountCents =
          newCashDistributionPercentage !== 100
            ? 0
            : distributionPreferences?.microredemptionAmountCents

        await updateDistributionPreferences({
          variables: {
            input: {
              legalEntityUuid: legalEntity?.uuid || legalEntityUuid,
              flexibleDistributionCents: Math.round(cashDistributionCents),
              microredemptionAmountCents,
            },
          },
          refetchQueries: [
            InvestorGetLegalEntitiesByInvestorAccountUuidDocument,
          ],
        })
      }

      notify('Successfully updated distribution preference.', 'success')
    } catch (e) {
      notify(
        'An error has occurred and our team has been notified. Please refresh and try again or contact our team for assistance.',
        'error'
      )
    }
  }

  const anyLoading =
    investorContextLoading ||
    updatesLoading ||
    equityInfoTimeSeriesLoading ||
    distributionPreferencesLoading

  useEffect(() => {
    if (activeOrderStatuses.length > 0 && !anyLoading) {
      setTimeout(() => {
        setShowOrders(true)
      }, 775)
    }
  }, [anyLoading, activeOrderStatuses.length])

  return (
    <>
      <SectionLayout name="home-section" columns={numColumns} id={id}>
        <Grid item xs={12}>
          {/* <Grid container columns={isMobile ? 4 : 9} spacing={isMobile ? 2 : 4}> */}
          <Grid container columns={numColumns} spacing={isMobile ? 2 : 4}>
            <Grid item xs={12}>
              <Typography
                variant="h3"
                sx={{ '& > .react-reveal': { display: 'inline-block' } }}
              >
                <StyledFade duration={500} distance="16px" bottom>
                  <>Welcome, {investorFirstName}.</>
                </StyledFade>
              </Typography>
            </Grid>

            {!isMobile && (
              <Grid item sm={6} md={9}>
                <Grid
                  container
                  columns={6}
                  spacing={4}
                  sx={{ flexWrap: 'nowrap!important' }}
                >
                  <Grid
                    item
                    sm={6}
                    md={!anyLoading && activeOrderStatuses.length > 0 ? 8 : 12}
                    sx={{ transition: 'all 750ms ease-in-out' }}
                  >
                    <HomeMessageCenter
                      messages={updates}
                      loading={updatesLoading}
                    />
                  </Grid>
                  {!isTablet &&
                    !anyLoading &&
                    activeOrderStatuses.length > 0 &&
                    showOrders && (
                      <Grid item xs={4}>
                        <Fade duration={500} distance="16px" right>
                          <ActiveOrdersCard />
                        </Fade>
                      </Grid>
                    )}
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
        {showPreferencesMobileDrawer && (
          <PreferencesMobileDrawer
            open={showPreferencesMobileDrawer}
            distributionPercent={100 - reinvestmentPercent}
            disableMicroredemptions={disableMicroredemptions}
            distributionPreferences={
              distributionPreferencesData?.getDistributionPreferences
                ?.distributionPreferences as Core_LegalEntityDistributionPreferences
            }
            onSubmit={confirmDistributions}
            onClose={() => setShowPreferencesMobileDrawer(false)}
          />
        )}

        <HomeMessageCenterMobile
          open={messageCenterMobileOpen || false}
          messages={updates}
          onClose={() =>
            setMessageCenterMobileOpen
              ? setMessageCenterMobileOpen(false)
              : undefined
          }
        />
      </SectionLayout>
      {showPreferencesModal && (
        <PreferencesModal
          open={showPreferencesModal}
          distributionPercent={100 - reinvestmentPercent}
          disableMicroredemptions={disableMicroredemptions}
          distributionPreferences={
            distributionPreferencesData?.getDistributionPreferences
              ?.distributionPreferences as Core_LegalEntityDistributionPreferences
          }
          onSubmit={confirmDistributions}
          onClose={() => setShowPreferencesModal(false)}
        />
      )}
    </>
  )
}

HomeSection.defaultProps = {
  messageCenterMobileOpen: undefined,
  setMessageCenterMobileOpen: undefined,
}

export default HomeSection

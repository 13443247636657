import theme from '@flock/shared-ui/src/theme/theme'
import { formatDollarsAndCents } from '@flock/utils'
import { useMediaQuery } from '@mui/material'
import { AccountValueDisplayProps } from './accountValueDisplayTypes'

const useAccountValueDisplay = (props: AccountValueDisplayProps) => {
  const { totalEquityValue, loading } = props

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'))

  return {
    isMobile,
    isTablet,
    totalEquityValueString: formatDollarsAndCents(totalEquityValue / 100),
    loading,
  }
}

export default useAccountValueDisplay

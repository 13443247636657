import React, { useEffect, useState } from 'react'
import {
  Modal,
  styled,
  Typography,
  Paper,
  CircularProgress,
  IconButton,
  Box,
} from '@mui/material'
import { Close } from '@mui/icons-material'
import { useQuery } from '@apollo/client'
import theme from '@flock/shared-ui/src/theme/theme'
import { TrackedLink } from '@flock/shared-ui'
import { InvestorGetDistributionPreferencesDocument } from '@flock/flock-gql-server/src/__generated__/graphql'
import BankAccountSection from './BankAccount/BankAccountSection'
import { useInvestorAccountContext } from '../../InvestorAccountContext'
import AuditLogModal from './AuditLog/AuditLog'
import AccountSelector from '../AccountSelector'
import PrimaryMailingAddressModal from './PrimaryMailingAddressModal'

const LoadingWrapper = styled('div')({
  position: 'absolute',
  top: '25%',
  left: '50%',
  transform: 'translate(-50%)',
})

const StyledCircularProgress = styled(CircularProgress)({
  color: theme.palette.trustBlue.main,
})

const ModalWrapper = styled(Paper)({
  position: 'absolute',
  top: '10%',
  left: '50%',
  transform: 'translate(-50%)',
  width: '544px',
  minHeight: '400px',
  maxHeight: '75vh',
  overflowY: 'auto',
  padding: '48px',
  borderRadius: '16px',
})

const SettingsGroupHeaderWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
})

const SettingsGroupHeader = styled(Typography)({
  paddingBottom: '0.4rem',
  display: 'flex',
  justifyContent: 'space-between',
})

const SettingsGroupContainer = styled('div')({
  paddingTop: '0.7rem',
  paddingBottom: '0.7rem',
})

const StyledSettingsItem = styled(Typography)({
  paddingTop: '0.25rem',
  paddingBottom: '0.25rem',
})

type SettingsItemProp = {
  label: string
  value: React.ReactNode
}

const SettingsItem = ({ label, value }: SettingsItemProp) => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    }}
  >
    <Box>
      <StyledSettingsItem variant="p2">{label}</StyledSettingsItem>
    </Box>
    <Box>
      <StyledSettingsItem variant="p2">{value}</StyledSettingsItem>
    </Box>
  </Box>
)

type SettingsModalProps = {
  open: boolean
  onClose: () => void
}

const SettingsModal = (props: SettingsModalProps) => {
  const { open, onClose } = props
  const { legalEntities, selectedLegalEntity, investorContextLoading } =
    useInvestorAccountContext()
  const [plaidAccountData, setPlaidAccountData] = useState({
    publicToken: '',
    accountIds: [''],
  })

  const [auditLogModalOpen, setAuditLogModalOpen] = useState(false)
  const [primaryAddressModalOpen, setPrimaryAddressModalOpen] = useState(false)

  const [bankAccountSettingsHidden, setBankAccountSettingsHidden] =
    useState(false)

  const [selectedLegalEntityUuid, setSelectedLegalEntityUuid] =
    useState<string>(selectedLegalEntity?.uuid)
  const [selectedLegalEntityIndex, setSelectedLegalEntityIndex] =
    useState<number>(0)

  const setSelectedLegalEntityUuidHelper = (uuid: string) => {
    setSelectedLegalEntityUuid(uuid)
  }

  const aggregateSelected = selectedLegalEntityUuid === 'aggregate'

  if (aggregateSelected) {
    setSelectedLegalEntityUuidHelper(legalEntities[1].uuid)
  }

  const {
    data: distributionPreferencesData,
    loading: distributionPreferencesLoading,
  } = useQuery(InvestorGetDistributionPreferencesDocument, {
    variables: {
      input: {
        legalEntityUuid: selectedLegalEntityUuid,
      },
    },
    skip: !selectedLegalEntityUuid || aggregateSelected,
  })

  useEffect(() => {
    if (legalEntities) {
      const idx =
        legalEntities.findIndex(
          (legalEntity) => legalEntity.uuid === selectedLegalEntityUuid
        ) || 0
      setSelectedLegalEntityIndex(idx)
    }
  }, [selectedLegalEntityUuid, legalEntities])

  const anyLoading =
    investorContextLoading ||
    distributionPreferencesLoading ||
    !distributionPreferencesData

  if (anyLoading) {
    return (
      <Modal open={open}>
        <LoadingWrapper>
          <StyledCircularProgress size="2rem" />
        </LoadingWrapper>
      </Modal>
    )
  }

  return (
    <Modal open={open}>
      <ModalWrapper
        sx={{
          display:
            bankAccountSettingsHidden ||
            auditLogModalOpen ||
            primaryAddressModalOpen
              ? 'none'
              : 'inherit',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'baseline',
            paddingBottom: '0.7rem',
          }}
        >
          <Typography variant="h3">
            Distribution Settings (
            {legalEntities[selectedLegalEntityIndex]?.name})
          </Typography>
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </Box>
        <AccountSelector
          selectedLegalEntityUuid={selectedLegalEntityUuid}
          includeAggregate={false}
          setSelectedLegalEntityUuidHelper={setSelectedLegalEntityUuidHelper}
        />
        <SettingsGroupContainer>
          <SettingsGroupHeaderWrapper>
            <SettingsGroupHeader variant="p1">
              Contact Information
            </SettingsGroupHeader>
            <TrackedLink onClick={() => setPrimaryAddressModalOpen(true)}>
              Edit
            </TrackedLink>
          </SettingsGroupHeaderWrapper>
          <SettingsItem
            label="Mailing Address"
            value={
              legalEntities[selectedLegalEntityIndex]?.address
                ?.formattedAddress || '-'
            }
          />
        </SettingsGroupContainer>
        <BankAccountSection
          selectedLegalEntityUuid={selectedLegalEntityUuid}
          setOuterHidden={setBankAccountSettingsHidden}
          plaidAccountData={plaidAccountData}
          setPlaidAccountData={setPlaidAccountData}
          setHasLegalEntityBankAccountData={() => {}}
          showCashDistributionRequirement={false}
        />
        <Typography>
          <TrackedLink onClick={() => setAuditLogModalOpen(true)}>
            View Activity Log
          </TrackedLink>
        </Typography>
        <AuditLogModal
          open={auditLogModalOpen}
          entityUuid={selectedLegalEntityUuid}
          onClose={() => setAuditLogModalOpen(false)}
        />
        <PrimaryMailingAddressModal
          open={primaryAddressModalOpen}
          legalEntityUuid={selectedLegalEntityUuid}
          onClose={() => setPrimaryAddressModalOpen(false)}
        />
      </ModalWrapper>
    </Modal>
  )
}

export default SettingsModal

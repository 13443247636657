import {
  Box,
  Modal,
  IconButton,
  styled,
  Typography,
  Paper,
  CircularProgress,
  Switch,
} from '@mui/material'
import { Close } from '@mui/icons-material'
import { TrackedButton, TrackedLink } from '@flock/shared-ui'
import React, { ChangeEvent, useEffect, useState } from 'react'
import { Core_LegalEntityDistributionPreferences } from '@flock/flock-gql-server/src/__generated__/graphql'

const SettingsGroupHeaderWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
})

const ModalWrapper = styled(Paper)({
  position: 'absolute',
  top: '40%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '500px',
  maxHeight: '75vh',
  overflowY: 'auto',
  padding: '2rem',
  borderRadius: '16px',
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
})

export type PreferencesModalProps = {
  open: boolean
  distributionPercent: number
  disableMicroredemptions: Boolean
  distributionPreferences: Core_LegalEntityDistributionPreferences
  onSubmit: (newValue: number, notifyPreference?: boolean) => Promise<void>
  onClose: () => void
}

const PreferencesModal = (props: PreferencesModalProps) => {
  const { open, distributionPercent, onSubmit, onClose } = props
  const [loading, setLoading] = useState(false)
  const [percent, setPercent] = useState(distributionPercent as number)

  useEffect(() => {
    setPercent(distributionPercent as number)
  }, [distributionPercent])

  const trackingName = 'preferences-modal'

  const submitAndClose = async () => {
    setLoading(true)
    await onSubmit(percent)
    setLoading(false)
    onClose()
  }

  const onChange = (_: ChangeEvent<HTMLInputElement>, newValue: boolean) => {
    if (newValue) {
      setPercent(0)
    } else {
      setPercent(100)
    }
  }

  return (
    <Modal open={open}>
      <ModalWrapper>
        <SettingsGroupHeaderWrapper
          sx={{ width: '100%', marginBottom: '1rem' }}
        >
          <Typography variant="h3">Distribution Preference</Typography>
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </SettingsGroupHeaderWrapper>

        <Box display="flex" justifyContent="space-between">
          <Box display="flex" flexDirection="column">
            <Typography variant="p1">Reinvest distributions?</Typography>
            <Typography variant="p2">
              {percent === 100
                ? 'You have elected to receive your distribution in cash.'
                : 'You have elected to reinvest your distribution.'}
            </Typography>
          </Box>
          <Switch onChange={onChange} checked={percent === 0} />
        </Box>

        <Box
          display="flex"
          justifyContent="flex-end"
          flexDirection="row"
          alignItems="center"
          gap="16px"
        >
          {loading ? (
            <CircularProgress />
          ) : (
            <>
              <TrackedLink
                trackingConfig={{ name: `${trackingName}-cancelled` }}
                variant="p1"
                onClick={onClose}
              >
                Cancel
              </TrackedLink>
              <TrackedButton
                variant="primary"
                size="smallForm"
                onClick={submitAndClose}
                sx={{
                  marginLeft: '1rem',
                }}
                trackingConfig={{ name: `${trackingName}-confirmed` }}
              >
                Confirm
              </TrackedButton>
            </>
          )}
        </Box>
      </ModalWrapper>
    </Modal>
  )
}

export default PreferencesModal

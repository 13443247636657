import {
  DocumentsFilledIcon,
  DocumentsIcon,
  HouseFilledIcon,
  HouseOutlinedIcon,
  PageContainer,
  OmSidebarWithIcons,
  AccountIcon,
  AccountFilledIcon,
  useInView,
  scrollIntoView,
  Om721Logo,
} from '@flock/shared-ui'
import { Box, Grid, Typography, useMediaQuery, useTheme } from '@mui/material'
import { navigate } from 'gatsby'
import React, { useEffect, useMemo, useState } from 'react'
import { RouteComponentProps } from '@reach/router'
import { ACCOUNT_URL, ORDERS_URL } from '../../constants'
import PageWrapper from '../SharedComponents/PageWrapper'
import HomeSection from './Home/HomeSection'
import DocumentsSection from './DocumentsSection'
import HeaderSection from './HeaderSection'
import LoadingCardPage from '../SharedComponents/LoadingCardPage'
import HeaderSectionMobile from './HeaderSectionMobile'
import { useInvestorAccountContext } from '../InvestorAccountContext'
import AccountSection from './AccountSection/AccountSection'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const InvestorDashboardPage = (_: RouteComponentProps) => {
  const [selectedSection, setSelectedSection] = useState(0)
  const {
    investorContextError,
    investorContextLoading,
    selectedLegalEntityInvestmentData,
    selectedLegalEntity,
    activeOrderStatuses,
  } = useInvestorAccountContext()
  const [messageCenterMobileOpen, setMessageCenterMobileOpen] = useState(false)

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isTablet = useMediaQuery(theme.breakpoints.down('md'))
  let numColumns = 12
  if (isMobile) {
    numColumns = 4
  } else if (isTablet) {
    numColumns = 7
  } else {
    numColumns = 12
  }

  const sidebarItems = useMemo(
    () => [
      {
        title: 'Home',
        unselectedIcon: HouseOutlinedIcon,
        selectedIcon: HouseFilledIcon,
        id: 'home',
      },
      {
        title: 'Account',
        unselectedIcon: AccountIcon,
        selectedIcon: AccountFilledIcon,
        id: 'account',
      },
      {
        title: 'Documents',
        unselectedIcon: DocumentsIcon,
        selectedIcon: DocumentsFilledIcon,
        id: 'documents',
      },
    ],
    []
  )

  const currentSection = useInView(sidebarItems.map((item) => item.id))

  const onChangeSidebar = (newTab: number) => {
    scrollIntoView(sidebarItems[newTab].id)
  }

  useEffect(() => {
    setSelectedSection(
      sidebarItems.map((item) => item.id).indexOf(currentSection)
    )
  }, [currentSection, sidebarItems])

  // auto redirect to orders page if there is an active order and no contribution amount
  useEffect(() => {
    if (
      !investorContextError &&
      !investorContextLoading &&
      activeOrderStatuses.length > 0
    ) {
      const firstOrder = activeOrderStatuses[0]
      if (
        selectedLegalEntity?.uuid &&
        selectedLegalEntityInvestmentData?.totalEquityValue === 0 &&
        firstOrder?.orderUuid
      ) {
        navigate(`${ORDERS_URL}/${firstOrder.orderUuid}`)
      }
    }
  }, [
    activeOrderStatuses.length,
    selectedLegalEntityInvestmentData,
    investorContextLoading,
    investorContextError,

    activeOrderStatuses,
    selectedLegalEntity?.uuid,
  ])

  return (
    <PageContainer title="Overmoon | Dashboard" trackingName="dashboard">
      {!selectedLegalEntityInvestmentData?.totalEquityValue ||
      // investorContextLoading ||
      !selectedLegalEntity?.uuid ? (
        <LoadingCardPage text="Preparing your dashboard..." />
      ) : (
        <>
          {isMobile && (
            <HeaderSectionMobile
              sidebarItems={sidebarItems}
              selectedSection={selectedSection}
              setMessageCenterOpen={setMessageCenterMobileOpen}
              onChangeSidebar={onChangeSidebar}
            />
          )}
          <PageWrapper>
            <Grid
              container
              spacing={4}
              columns={numColumns}
              height="auto"
              pl={{
                xs: '32px',
                md: '0px',
              }}
              pr={{
                xs: '32px',
                md: '0px',
              }}
            >
              {!isMobile && (
                <>
                  <Grid item xs={numColumns}>
                    <Box position="relative">
                      <HeaderSection />
                    </Box>
                  </Grid>
                  <Grid item md={3} sm={1}>
                    <Box
                      display="flex"
                      flexDirection="column"
                      sx={{ position: 'sticky', top: '8%' }}
                    >
                      {!isTablet && (
                        <Box
                          onClick={() => navigate(ACCOUNT_URL)}
                          top="28px"
                          pb="20px"
                        >
                          <Typography
                            variant="h4"
                            fontWeight={500}
                            color="midnightBlue.main"
                            sx={{ '&:hover': { cursor: 'pointer' } }}
                          >
                            <Om721Logo width="239px" height="31px" />
                          </Typography>
                        </Box>
                      )}

                      <OmSidebarWithIcons
                        items={sidebarItems}
                        onChange={onChangeSidebar}
                        selected={selectedSection}
                      />
                    </Box>
                  </Grid>
                </>
              )}
              <Grid item xs={4} sm={6} md={9}>
                <Grid container spacing={8}>
                  {/* All sections go here */}

                  <HomeSection
                    id={sidebarItems[0].id}
                    messageCenterMobileOpen={
                      isMobile ? messageCenterMobileOpen : undefined
                    }
                    setMessageCenterMobileOpen={
                      isMobile ? setMessageCenterMobileOpen : undefined
                    }
                  />

                  <AccountSection id={sidebarItems[1].id} />
                  <DocumentsSection id={sidebarItems[2].id} />
                </Grid>
              </Grid>
            </Grid>
          </PageWrapper>
        </>
      )}
    </PageContainer>
  )
}

export default InvestorDashboardPage

export const OM_DEFAULT_DISTRIBUTION_FACTOR = 0.04
export const OM_DEFAULT_ONBOARDING_FEE = 0.035
export const OM_DEFAULT_CLOSING_AND_LEGAL_FEE = 0.01
export const OM_DEFAULT_PRICE_PER_SHARE = 1000
export const OM_SHARE_PRICE_LAST_UPDATED = 'June 30, 2023'
export const OM_MANAGEMENT_FEE = 0.01
export const OM_DEFAULT_FUND_EQUITY_PERCENT_TARGET = 1
export const OM_FUND_STARTING_YEAR = 2021
export const OM_FUND_STARTING_QUARTER = 2
export const OM_VALID_STATES = ['FL', 'TN']

import React, { useState } from 'react'
import {
  Box,
  Drawer,
  Grid,
  IconButton,
  Modal,
  Paper,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import {
  InvestorEditLegalEntityMutationDocument,
  InvestorDashboardGetAuditLogsDocument,
  InvestorGetLegalEntitiesByInvestorAccountUuidDocument,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import {
  AnyInputConfig,
  CloseMobileIcon,
  GridForm,
  InputType,
} from '@flock/shared-ui'
import { Close } from '@mui/icons-material'
import { useMutation } from '@apollo/client'
import { useSnackbar } from '@flock/flock-component-library'

const SettingsGroupHeaderWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
})

const SmallModalWrapper = styled(Paper)({
  position: 'absolute',
  top: '10%',
  left: '50%',
  transform: 'translate(-50%)',
  width: '25rem',
  maxHeight: '75vh',
  padding: '2rem',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  borderRadius: '24px',
})

type PrimaryMailingAddressModalProps = {
  open: boolean
  legalEntityUuid: string
  onClose: () => void
}

const PrimaryMailingAddressModal = (props: PrimaryMailingAddressModalProps) => {
  const { open, legalEntityUuid, onClose } = props
  const [loading, setLoading] = useState(false)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const { notify } = useSnackbar()
  const [editLegalEntity] = useMutation(InvestorEditLegalEntityMutationDocument)

  const inputConfigs: AnyInputConfig[] = [
    {
      name: 'primaryMailingAddress',
      type: InputType.Address,
      required: true,
      props: {
        label: 'Please enter your mailing address',
        fullWidth: true,
        variant: 'outlined',
      },
    },
  ]

  const submitInfo = async (result: any) => {
    try {
      setLoading(true)

      await editLegalEntity({
        variables: {
          editLegalEntityInput: {
            uuid: legalEntityUuid as string,
            primaryMailingAddress:
              result.primaryMailingAddress?.formattedAddress,
          },
        },
        refetchQueries: [
          InvestorGetLegalEntitiesByInvestorAccountUuidDocument,
          InvestorDashboardGetAuditLogsDocument,
        ],
      })
      setLoading(false)
      notify('Successfully updated mailing address')
      onClose()
    } catch (e) {
      notify(
        'An error has occurred and our team has been notified. Please refresh and try again or contact our team for assistance.',
        'error'
      )
      setLoading(false)
    }
  }

  return (
    <>
      {isMobile ? (
        <Drawer open={open} PaperProps={{ sx: { width: '100%' } }}>
          <Box position="fixed" top="8px" right="8px">
            <IconButton onClick={onClose}>
              <CloseMobileIcon />
            </IconButton>
          </Box>
          <Grid columns={4} spacing={2} pl="32px" pr="32px">
            <Box width="100%" display="flex" flexDirection="column" gap="40px">
              <SettingsGroupHeaderWrapper
                sx={{ width: '100%', marginBottom: '1rem', pt: '64px' }}
              >
                <Typography variant="h3">Mailing Address</Typography>
              </SettingsGroupHeaderWrapper>
              <GridForm
                onSubmit={submitInfo}
                inputConfigs={inputConfigs}
                loading={loading}
                ctaBoxProps={{
                  width: '114px',
                  position: 'fixed',
                  bottom: '40px',
                  right: '32px',
                }}
                gridProps={{
                  spacing: 3,
                }}
                ctaText="Submit"
              />
            </Box>
          </Grid>
        </Drawer>
      ) : (
        <Modal open={open}>
          <SmallModalWrapper>
            <SettingsGroupHeaderWrapper
              sx={{ width: '100%', marginBottom: '1rem' }}
            >
              <Typography variant="h3">Mailing Address</Typography>
              <IconButton onClick={onClose}>
                <Close />
              </IconButton>
            </SettingsGroupHeaderWrapper>
            <GridForm
              onSubmit={submitInfo}
              inputConfigs={inputConfigs}
              loading={loading}
              gridProps={{
                spacing: 3,
              }}
              ctaText="Submit"
            />
          </SmallModalWrapper>
        </Modal>
      )}
    </>
  )
}

export default PrimaryMailingAddressModal

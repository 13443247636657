// These set up types for segment analytics
/// <reference types="@types/segment-analytics" />

import { UserEventType } from '@flock/utils'
import { Core_CreateInvestorFrontendEventRequestInput } from '@flock/flock-gql-server/src/__generated__/graphql'

declare global {
  interface Window {
    analytics: SegmentAnalytics.AnalyticsJS
  }
}

// If logSegment is true, events are not sent to segment and are
// instead logged to the console.
const logSegment = process.env.GATSBY_LOG_SEGMENT === 'true'

const debugSegment = () =>
  logSegment || window.location.href.includes('localhost')

// @ts-ignore
let shouldNotTrack = false
if (typeof window !== 'undefined' && !debugSegment()) {
  shouldNotTrack =
    window.doNotTrack === '1' ||
    navigator.doNotTrack === 'yes' ||
    navigator.doNotTrack === '1'
}

// gets the part of the url after the .com
// and excludes query params
const getEventUrlPath = () => {
  if (typeof window === 'undefined') {
    return ''
  }
  return window.location.pathname + window.location.hash
}

const getPageTitle = () => {
  if (typeof window !== 'undefined') {
    return window.document.title
  }
  return ''
}

export const flockTrack = (
  eventType: string,
  slug: string | undefined | null,
  sendEvent: (input: Core_CreateInvestorFrontendEventRequestInput) => void,
  investorAccountUuid: string,
  additionalInfo?: Object,
  title?: string
) => {
  const input: Core_CreateInvestorFrontendEventRequestInput = {
    actionType: eventType,
    slug,
    urlPath: getEventUrlPath(),
    pageTitle: title || getPageTitle(),
    investorAccountUuid,
    eventTime: new Date().getTime(),
    additionalInfo: JSON.stringify(additionalInfo),
    referer: document.referrer,
    userAgent: window.navigator.userAgent,
  }
  sendEvent(input)
}

export const track = (
  name: string,
  properties?: any,
  actionType?: string,
  sendEvent?: (input: Core_CreateInvestorFrontendEventRequestInput) => void,
  slug?: string,
  investorAccountUuid?: string,
  title?: string
) => {
  if (shouldNotTrack) {
    return
  }
  if (actionType && sendEvent && investorAccountUuid) {
    // the slug passed in is the identifier for what section of the page (using tracking context) so
    // still need to join with name of action to identify specific action on page.
    let eventSlug = name
    if (slug && name) {
      eventSlug = `${slug}/${name}`
    }
    flockTrack(
      actionType,
      eventSlug,
      sendEvent,
      investorAccountUuid,
      properties,
      title
    )
  }

  if (debugSegment()) {
    console.log('Segment Track', name, properties)
  } else {
    window.analytics.track(name, properties)
  }
}

export const trackPage = (
  name: string,
  properties: any,
  sendEvent?: (input: Core_CreateInvestorFrontendEventRequestInput) => void,
  investorAccountUuid?: string,
  title?: string
) => {
  if (shouldNotTrack) {
    return
  }

  if (debugSegment()) {
    console.log('Segment Page', name, properties)
  } else {
    window.analytics.page({
      title: name,
      url: `${window.location.origin}/${name}`,
      path: `/${name}`,
      search: `${window.location.search}`,
    })
  }
  track(
    name,
    {
      ...properties,
    },
    UserEventType.PAGE_VISIT,
    sendEvent,
    '',
    investorAccountUuid,
    title
  )
}

type IdentifyProperties = {
  userId: string
  type: 'legalEntity' | 'investorAccount'
}

export const identify = (properties: IdentifyProperties) => {
  if (shouldNotTrack) {
    return
  }

  if (debugSegment()) {
    console.log('Segment Identify', properties)
  } else {
    const { userId, ...otherProperties } = properties
    window.analytics.identify(userId, {
      ...otherProperties,
    })
  }
}

import React, { useEffect, useState } from 'react'
import {
  Modal,
  styled,
  Typography,
  CircularProgress,
  IconButton,
  Drawer,
  Grid,
  Box,
} from '@mui/material'
import { useQuery } from '@apollo/client'
import { CloseMobileIcon, TrackedLink } from '@flock/shared-ui'
import theme from '@flock/shared-ui/src/theme/theme'
import {
  Core_LegalEntityDistributionPreferences,
  InvestorGetDistributionPreferencesDocument,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import FlexibleDistributionSection from './FlexibleDistributions/FlexibleDistributionsSection'
import BankAccountSection from './BankAccount/BankAccountSection'
import { useInvestorAccountContext } from '../../InvestorAccountContext'
import AuditLogModal from './AuditLog/AuditLog'
import AccountSelector from '../AccountSelector'
import PrimaryMailingAddressModal from './PrimaryMailingAddressModal'

const LoadingWrapper = styled('div')({
  position: 'absolute',
  top: '25%',
  left: '50%',
  transform: 'translate(-50%)',
})

const StyledCircularProgress = styled(CircularProgress)({
  color: theme.palette.trustBlue.main,
})

const SettingsGroupHeaderWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
})

const SettingsGroupHeader = styled(Typography)({
  paddingBottom: '0.4rem',
  display: 'flex',
  justifyContent: 'space-between',
})

const SettingsGroupContainer = styled('div')({
  paddingTop: '0.7rem',
  paddingBottom: '0.7rem',
})

const StyledSettingsItem = styled(Typography)({
  paddingTop: '0.25rem',
  paddingBottom: '0.25rem',
})

type SettingsItemProp = {
  label: string
  value: React.ReactNode
}

const SettingsItem = ({ label, value }: SettingsItemProp) => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    }}
  >
    <Box>
      <StyledSettingsItem variant="p2">{label}</StyledSettingsItem>
    </Box>
    <Box>
      <StyledSettingsItem variant="p2">{value}</StyledSettingsItem>
    </Box>
  </Box>
)

type SettingsMobileDrawerProps = {
  open: boolean
  onClose: () => void
}

const SettingsMobileDrawer = (props: SettingsMobileDrawerProps) => {
  const { open, onClose } = props
  const { legalEntities, selectedLegalEntity, investorContextLoading } =
    useInvestorAccountContext()
  const [plaidAccountData, setPlaidAccountData] = useState({
    publicToken: '',
    accountIds: [''],
  })
  const [hasLegalEntityBankAccountData, setHasLegalEntityBankAccountData] =
    useState(false)
  const [bankAccountSettingsHidden, setBankAccountSettingsHidden] =
    useState(false)
  const [auditLogModalOpen, setAuditLogModalOpen] = useState(false)
  const [primaryAddressModalOpen, setPrimaryAddressModalOpen] = useState(false)

  const [selectedLegalEntityUuid, setSelectedLegalEntityUuid] =
    useState<string>(selectedLegalEntity?.uuid)
  const [selectedLegalEntityIndex, setSelectedLegalEntityIndex] =
    useState<number>(0)

  const setSelectedLegalEntityUuidHelper = (uuid: string) => {
    setSelectedLegalEntityUuid(uuid)
  }

  const aggregateSelected = selectedLegalEntityUuid === 'aggregate'

  if (aggregateSelected) {
    setSelectedLegalEntityUuidHelper(legalEntities[1].uuid)
  }

  const {
    data: distributionPreferencesData,
    loading: distributionPreferencesLoading,
  } = useQuery(InvestorGetDistributionPreferencesDocument, {
    variables: {
      input: {
        legalEntityUuid: selectedLegalEntityUuid,
      },
    },
    skip: !selectedLegalEntityUuid || aggregateSelected,
  })

  useEffect(() => {
    if (legalEntities) {
      const idx =
        legalEntities.findIndex(
          (legalEntity) => legalEntity.uuid === selectedLegalEntityUuid
        ) || 0
      setSelectedLegalEntityIndex(idx)
    }
  }, [selectedLegalEntityUuid, legalEntities])

  const anyLoading =
    investorContextLoading ||
    distributionPreferencesLoading ||
    !distributionPreferencesData

  if (anyLoading) {
    return (
      <Modal open={open}>
        <LoadingWrapper>
          <StyledCircularProgress size="2rem" />
        </LoadingWrapper>
      </Modal>
    )
  }
  const distributionPreferences = distributionPreferencesData
    ?.getDistributionPreferences
    ?.distributionPreferences as Core_LegalEntityDistributionPreferences
  return (
    <Drawer
      disableEnforceFocus
      open={open}
      PaperProps={{
        sx: {
          width: '100%',
          display:
            bankAccountSettingsHidden || primaryAddressModalOpen
              ? 'none'
              : 'inherit',
        },
      }}
    >
      <Box position="absolute" top="8px" right="8px">
        <IconButton onClick={onClose}>
          <CloseMobileIcon />
        </IconButton>
      </Box>
      <Grid columns={4} spacing={2} pl="32px" pr="32px" pb="32px">
        <Box width="100%" display="flex" flexDirection="column" gap="40px">
          <SettingsGroupHeaderWrapper
            sx={{ width: '100%', marginBottom: '1rem', pt: '64px' }}
          >
            <Typography variant="h3">
              Distribution Settings (
              {legalEntities[selectedLegalEntityIndex]?.name})
            </Typography>
          </SettingsGroupHeaderWrapper>
        </Box>
        <AccountSelector
          selectedLegalEntityUuid={selectedLegalEntityUuid}
          includeAggregate={false}
          setSelectedLegalEntityUuidHelper={setSelectedLegalEntityUuidHelper}
        />
        <SettingsGroupContainer>
          <SettingsGroupHeaderWrapper>
            <SettingsGroupHeader variant="p1">
              Contact Information
            </SettingsGroupHeader>
            <TrackedLink onClick={() => setPrimaryAddressModalOpen(true)}>
              Edit
            </TrackedLink>
          </SettingsGroupHeaderWrapper>
          <SettingsItem
            label="Mailing Address"
            value={
              legalEntities[selectedLegalEntityIndex]?.address
                ?.formattedAddress || '-'
            }
          />
        </SettingsGroupContainer>
        <BankAccountSection
          selectedLegalEntityUuid={selectedLegalEntityUuid}
          setOuterHidden={setBankAccountSettingsHidden}
          plaidAccountData={plaidAccountData}
          setPlaidAccountData={setPlaidAccountData}
          setHasLegalEntityBankAccountData={setHasLegalEntityBankAccountData}
          showCashDistributionRequirement={false}
        />
        <FlexibleDistributionSection
          cashDistributionPercentage={
            legalEntities[selectedLegalEntityIndex]
              ?.flexibleDistributionPercent!
          }
          distributionPreferences={distributionPreferences}
          hasLegalEntityBankAccountData={hasLegalEntityBankAccountData}
          legalEntityUuid={selectedLegalEntityUuid}
        />
        <Typography>
          <TrackedLink onClick={() => setAuditLogModalOpen(true)}>
            View Activity Log
          </TrackedLink>
        </Typography>
        <AuditLogModal
          open={auditLogModalOpen}
          entityUuid={selectedLegalEntityUuid}
          onClose={() => setAuditLogModalOpen(false)}
        />
        <PrimaryMailingAddressModal
          open={primaryAddressModalOpen}
          legalEntityUuid={selectedLegalEntityUuid}
          onClose={() => setPrimaryAddressModalOpen(false)}
        />
      </Grid>
    </Drawer>
  )
}

export default SettingsMobileDrawer

import { TrackedButton } from '@flock/shared-ui'
import { Close } from '@mui/icons-material'
import {
  Box,
  IconButton,
  Modal,
  Paper,
  styled,
  Typography,
} from '@mui/material'
import React from 'react'
import { OrderProgressState } from './OrderV3HomeSection'

const ModalWrapper = styled(Paper)({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '500px',
  maxHeight: '80vh',
  overflowY: 'auto',
  padding: '40px',
  borderRadius: '16px',
})

type OrderStepsModalProps = {
  progressState: OrderProgressState
  openPersonalInfoUrl: () => void
  isOpen: boolean
  onClose: () => void
}

const OrderStepsModal = (props: OrderStepsModalProps) => {
  const { progressState, openPersonalInfoUrl, isOpen, onClose } = props
  const { progressBarConfigs } = progressState

  let hasPersonalInformation = false
  let hasPropertyQuestionnaire = false
  let hasTaxQuestionnaire = false
  let hasTitleAuthorizationAndOwnerAffidavit = false

  progressBarConfigs.forEach((config) => {
    if (config.text === 'Personal Information') {
      hasPersonalInformation = true
    }
    if (config.text === 'Property Questionnaire') {
      hasPropertyQuestionnaire = true
    }
    if (config.text === 'Tax Questionnaire') {
      hasTaxQuestionnaire = true
    }
    if (config.text === 'Title Authorization & Owner Affidavit') {
      hasTitleAuthorizationAndOwnerAffidavit = true
    }
  })

  return (
    <Modal open={isOpen} onClose={onClose}>
      <ModalWrapper>
        <Box display="flex" flexDirection="row" justifyContent="flex-end">
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </Box>
        <Box display="flex" flexDirection="column" gap="16px">
          <Typography variant="p1" color="midnightBlue.main" fontWeight={500}>
            To finalize your exchange, you&apos;ll need to complete these
            onboarding tasks:
          </Typography>
          {hasPersonalInformation && (
            <Box display="flex" flexDirection="row" gap="8px" pl="8px">
              &bull;
              <Typography variant="p3" color="midnightBlue.main">
                <Typography
                  fontWeight={500}
                  variant="p3"
                  color="midnightBlue.main"
                >
                  Personal Information:
                </Typography>{' '}
                Share information for each person involved in your transaction
              </Typography>
            </Box>
          )}
          {hasPropertyQuestionnaire && (
            <Box display="flex" flexDirection="row" gap="8px" pl="8px">
              &bull;
              <Typography variant="p3" color="midnightBlue.main">
                <Typography
                  fontWeight={500}
                  variant="p3"
                  color="midnightBlue.main"
                >
                  Property Questionnaire:
                </Typography>{' '}
                Answer more detailed property-specific questions to prepare our
                Asset Management team
              </Typography>
            </Box>
          )}
          {hasTaxQuestionnaire && (
            <Box display="flex" flexDirection="row" gap="8px" pl="8px">
              &bull;
              <Typography variant="p3" color="midnightBlue.main">
                <Typography
                  fontWeight={500}
                  variant="p3"
                  color="midnightBlue.main"
                >
                  Tax Questionnaire:
                </Typography>{' '}
                Ensure you utilize all of the Overmoon 721 Fund&apos;s tax
                advantages by supplying the requested documentation
              </Typography>
            </Box>
          )}
          {hasTitleAuthorizationAndOwnerAffidavit && (
            <Box display="flex" flexDirection="row" gap="8px" pl="8px">
              &bull;
              <Typography variant="p3" color="midnightBlue.main">
                <Typography
                  fontWeight={500}
                  variant="p3"
                  color="midnightBlue.main"
                >
                  Title Authorization &amp; Owner Affidavit:
                </Typography>{' '}
                Grant authorization for our title partner to process your
                transaction
              </Typography>
            </Box>
          )}

          <Box display="flex" flexDirection="row" gap="8px" pl="8px">
            &bull;
            <Typography variant="p3" color="midnightBlue.main">
              <Typography
                fontWeight={500}
                variant="p3"
                color="midnightBlue.main"
              >
                LP Onboarding:
              </Typography>{' '}
              Complete subscription documents and accredited investor
              verification
            </Typography>
          </Box>

          <Typography variant="p3" color="midnightBlue.main">
            Overmoon&apos;s title partner, {progressState.titleCompany}, will
            reach out to schedule your closing appointment. They may reach out
            with additional questions as well.
          </Typography>
          {openPersonalInfoUrl && (
            <Box
              width="100%"
              justifyContent="center"
              display="flex"
              flexDirection="row"
            >
              <Box>
                <TrackedButton
                  variant="primary"
                  size="mini"
                  onClick={() => {
                    openPersonalInfoUrl()
                  }}
                >
                  Get Started
                </TrackedButton>
              </Box>
            </Box>
          )}
        </Box>
      </ModalWrapper>
    </Modal>
  )
}

export default OrderStepsModal

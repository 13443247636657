import React, { useCallback, useState } from 'react'
import {
  Box,
  Modal,
  Paper,
  styled,
  Typography,
  CircularProgress,
  Button,
  IconButton,
  useMediaQuery,
  Drawer,
  Grid,
} from '@mui/material'
import { Close } from '@mui/icons-material'
import { useQuery } from '@apollo/client'
import theme from '@flock/shared-ui/src/theme/theme'
import { useSnackbar } from '@flock/flock-component-library'
import { TrackedLink, TrackedButton, CloseMobileIcon } from '@flock/shared-ui'
import {
  InvestorGetPlaidLinkTokenDocument,
  InvestorLegalEntityBankAccountDocument,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import PlaidLink from './PlaidLink'
import ManualBankEntryModal from './ManualBankEntryModal'
import PersonalOrBusinessModal from './PersonalOrBusinessModal'

const SmallModalWrapper = styled(Paper)({
  position: 'absolute',
  top: '10%',
  left: '50%',
  transform: 'translate(-50%)',
  width: '25rem',
  maxHeight: '75vh',
  padding: '2rem',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  borderRadius: '24px',
})

const SettingsGroupHeader = styled(Typography)({
  paddingBottom: '0.4rem',
  display: 'flex',
  justifyContent: 'space-between',
})

const SettingsGroupHeaderWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
})

const SettingsGroupContainer = styled('div')({
  paddingTop: '0.7rem',
  paddingBottom: '0.7rem',
})

const StyledSettingsItem = styled(Typography)({
  paddingTop: '0.25rem',
  paddingBottom: '0.25rem',
})

type SettingsItemProp = {
  label: string
  value: React.ReactNode
}

const SettingsItem = ({ label, value }: SettingsItemProp) => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    }}
  >
    <Box>
      <StyledSettingsItem variant="p2">{label}</StyledSettingsItem>
    </Box>
    <Box>
      <StyledSettingsItem variant="p2">{value}</StyledSettingsItem>
    </Box>
  </Box>
)

type BankAccountSectionProps = {
  selectedLegalEntityUuid: any
  setOuterHidden: any
  plaidAccountData: any
  setPlaidAccountData: any
  setHasLegalEntityBankAccountData: any
  showCashDistributionRequirement: boolean
  hideError?: boolean
}

const BankAccountSection = (props: BankAccountSectionProps) => {
  const {
    selectedLegalEntityUuid,
    setOuterHidden,
    plaidAccountData,
    setPlaidAccountData,
    setHasLegalEntityBankAccountData,
    showCashDistributionRequirement,
    hideError,
  } = props
  const [personalOrBusinessOpen, setPersonalOrBusinessOpen] = useState(false)
  const [linkMethodModalOpen, setLinkMethodModalOpen] = useState(false)
  const [manualBankEntryOpen, setManualBankEntryOpen] = useState(false)
  const { notify } = useSnackbar()

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const openLinkMethodModal = () => {
    setLinkMethodModalOpen(true)
    setOuterHidden?.(true)
  }

  const onCloseLinkMethodModal = () => {
    setLinkMethodModalOpen(false)
    setOuterHidden?.(false)
  }

  const onCloseManualEntryModal = () => {
    setManualBankEntryOpen(false)
    setOuterHidden?.(false)
  }

  const handlePlaidAccountData = useCallback(
    (publicToken: string, accountIds: string[]) => {
      setPlaidAccountData({
        plaidPublicToken: publicToken,
        plaidAccountIds: accountIds,
      })
      setPersonalOrBusinessOpen(true)
      setLinkMethodModalOpen(false)
      setOuterHidden?.(true)
    },
    [setOuterHidden, setPlaidAccountData]
  )

  const onCompletePersonalOrBusiness = () => {
    setPersonalOrBusinessOpen(false)
    setOuterHidden?.(false)
  }

  const { data: legalEntityBankAccountData, loading: bankAccountLoading } =
    useQuery(InvestorLegalEntityBankAccountDocument, {
      fetchPolicy: 'no-cache',
      onError: () => {
        if (!hideError) {
          notify(
            'A network error occurred while fetching your account details. Please refresh the page or contact us if the error persists.',
            'error'
          )
        }
      },
      variables: {
        input: {
          legalEntityUuid: selectedLegalEntityUuid,
          // legalEntityUuidOverride || adminLegalEntityUuidOverride,
        },
      },
      skip: !selectedLegalEntityUuid,
    })

  const { data: plaidLinkData } = useQuery(InvestorGetPlaidLinkTokenDocument, {
    variables: {
      input: {
        legalEntityUuid: selectedLegalEntityUuid,
      },
    },
    skip: !selectedLegalEntityUuid,
    onError: () => {
      if (!hideError) {
        notify(
          'An issue occurred connecting to Plaid. Please refresh the page or contact us if the error persists.',
          'error'
        )
      }
    },
  })

  const trackingName = 'bank-account-modal'

  const bankAccount =
    legalEntityBankAccountData?.legalEntityBankAccount?.bankAccount

  if (bankAccount) {
    setHasLegalEntityBankAccountData?.(true)
  } else {
    setHasLegalEntityBankAccountData?.(false)
  }

  let bankAccountType = '-'
  if (bankAccount) {
    bankAccountType = bankAccount?.isPersonal ? 'Personal' : 'Business'
  }

  return (
    <>
      <SettingsGroupContainer>
        {showCashDistributionRequirement ? (
          <>
            <SettingsGroupHeader variant="p1">Bank Account</SettingsGroupHeader>
            <Typography sx={{ typography: 'body2' }}>
              A bank account is currently only required for Cash Distributions.
              You can update this information at any time.
            </Typography>
            <SettingsGroupContainer>
              <Button
                variant="outlined"
                color="primary"
                size="small"
                onClick={openLinkMethodModal}
              >
                <Typography>Update Bank Account Information</Typography>
              </Button>
            </SettingsGroupContainer>
          </>
        ) : (
          <>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'baseline',
              }}
            >
              <SettingsGroupHeader variant="p1">
                Bank Account
              </SettingsGroupHeader>
              <TrackedLink onClick={openLinkMethodModal}>Edit</TrackedLink>
            </Box>
          </>
        )}

        <SettingsItem
          label="Account Name"
          value={
            bankAccountLoading ? (
              <CircularProgress size="1rem" />
            ) : (
              bankAccount?.name || '-'
            )
          }
        />
        <SettingsItem
          label="Account Type"
          value={
            bankAccountLoading ? (
              <CircularProgress size="1rem" />
            ) : (
              bankAccountType
            )
          }
        />
        <SettingsItem
          label="Account Number"
          value={
            bankAccountLoading ? (
              <CircularProgress size="1rem" />
            ) : (
              // formatting masked account number e.g. *****4321
              bankAccount?.maskedAccountNumber.padStart(9, '*') || '-'
            )
          }
        />
        <SettingsItem
          label="Routing Number"
          value={
            bankAccountLoading ? (
              <CircularProgress size="1rem" />
            ) : (
              bankAccount?.routingNumber || '-'
            )
          }
        />
      </SettingsGroupContainer>
      <PersonalOrBusinessModal
        selectedLegalEntityUuid={selectedLegalEntityUuid}
        open={personalOrBusinessOpen}
        onSubmit={onCompletePersonalOrBusiness}
        plaidAccountData={plaidAccountData}
      />
      {isMobile ? (
        <Drawer
          open={linkMethodModalOpen}
          PaperProps={{ sx: { width: '100%' } }}
        >
          <Box position="fixed" top="8px" right="8px">
            <IconButton onClick={onCloseLinkMethodModal}>
              <CloseMobileIcon />
            </IconButton>
          </Box>
          <Grid columns={4} spacing={2} pl="32px" pr="32px">
            <Box width="100%" display="flex" flexDirection="column" gap="40px">
              <SettingsGroupHeaderWrapper
                sx={{ width: '100%', marginBottom: '1rem', pt: '64px' }}
              >
                <Typography variant="h3">Bank Account</Typography>
              </SettingsGroupHeaderWrapper>
              <PlaidLink
                linkToken={
                  plaidLinkData?.getPlaidLinkToken?.linkToken as string
                }
                handlePlaidAccountData={handlePlaidAccountData}
              />
              <Typography
                sx={{
                  marginTop: '20px',
                  color: theme.palette.gray7.main,
                }}
                variant="p1"
              >
                or{' '}
                <TrackedLink
                  onClick={() => {
                    setManualBankEntryOpen(true)
                    setLinkMethodModalOpen(false)
                  }}
                >
                  manually enter your bank account details
                </TrackedLink>
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'flex-end',
                  marginTop: '3rem',
                }}
              >
                <TrackedButton
                  variant="primary"
                  size="smallForm"
                  trackingConfig={{ name: `${trackingName}-close` }}
                  onClick={onCloseLinkMethodModal}
                  sx={{
                    width: '114px',
                    position: 'fixed',
                    bottom: '40px',
                    right: '32px',
                  }}
                >
                  Close
                </TrackedButton>
              </Box>
            </Box>
          </Grid>
        </Drawer>
      ) : (
        <Modal open={linkMethodModalOpen}>
          <SmallModalWrapper>
            <SettingsGroupHeaderWrapper
              sx={{ width: '100%', marginBottom: '4rem' }}
            >
              <Typography variant="h3">Bank Account</Typography>
              <IconButton onClick={onCloseLinkMethodModal}>
                <Close />
              </IconButton>
            </SettingsGroupHeaderWrapper>
            <PlaidLink
              linkToken={plaidLinkData?.getPlaidLinkToken?.linkToken as string}
              handlePlaidAccountData={handlePlaidAccountData}
            />
            <Typography
              sx={{
                marginTop: '20px',
                color: theme.palette.gray7.main,
              }}
              variant="p1"
            >
              or{' '}
              <TrackedLink
                onClick={() => {
                  setManualBankEntryOpen(true)
                  setLinkMethodModalOpen(false)
                }}
              >
                manually enter your bank account details
              </TrackedLink>
            </Typography>
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                justifyContent: 'flex-end',
                marginTop: '3rem',
              }}
            >
              <TrackedButton
                variant="primary"
                size="small"
                trackingConfig={{ name: `${trackingName}-close` }}
                onClick={onCloseLinkMethodModal}
              >
                Close
              </TrackedButton>
            </Box>
          </SmallModalWrapper>
        </Modal>
      )}
      <ManualBankEntryModal
        selectedLegalEntityUuid={selectedLegalEntityUuid}
        open={manualBankEntryOpen}
        onClose={onCloseManualEntryModal}
      />
    </>
  )
}

BankAccountSection.defaultProps = {
  hideError: false,
}

export default BankAccountSection

import {
  blueOvermoonTheme,
  DocumentsFilledIcon,
  DocumentsIcon,
  HouseFilledIcon,
  HouseOutlinedIcon,
  OM_INVESTOR_EMAIL,
  Om721Logo,
  OmSidebarWithIcons,
  PageContainer,
  scrollIntoView,
  TrackedLink,
  useInView,
} from '@flock/shared-ui'
import { Box, Grid, ThemeProvider, Typography } from '@mui/material'
import { navigate } from 'gatsby'
import { RouteComponentProps } from '@reach/router'

import React, { useEffect, useMemo, useState } from 'react'
import { localStore } from '@flock/utils'
import { ACCOUNT_URL } from '../../../constants'
import PageWrapper from '../../SharedComponents/PageWrapper'

import LoadingCardPage from '../../SharedComponents/LoadingCardPage'
import { useInvestorAccountContext } from '../../InvestorAccountContext'
import OrderSelector from '../OrderPageComponents/OrderSelector'
import useOrderV3Page from './useOrderV3Page'
import { OrderV3PageProps } from './orderV3Types'
import OrderV3HomeSection from './OrderV3HomeSection'
import PreContributionAgreementSignedModal from './PreContributionAgreementSignedModal'
import PostContributionAgreementSignedModal from './PostContributionAgreementSignedModal'
import DocumentsSection from '../../InvestorDashboardComponents/DocumentsSection'
import SignContributionAgreementModal from './SignContributionAgreementModal'
import DocusignErrorModal from './DocusignErrorModal'
import OrderStepsModal from './OrderStepsModal'

const OrderErrorCard = () => (
  <Box
    display="flex"
    flexDirection="column"
    alignItems="center"
    justifyContent="center"
    textAlign="center"
    height="80vh"
    gap="16px"
    paddingLeft="16px"
    paddingRight="16px"
  >
    <Typography variant="h4">
      We ran into an error loading your order.
    </Typography>
    <Typography variant="p2">
      Please refresh the page or contact{' '}
      <TrackedLink onClick={() => window.open(`mailto:${OM_INVESTOR_EMAIL}`)}>
        {OM_INVESTOR_EMAIL}
      </TrackedLink>{' '}
      for assistance.
    </Typography>
  </Box>
)

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const OrderV3Page = (_: RouteComponentProps) => {
  const { activeOrderStatuses, legalEntities } = useInvestorAccountContext()
  const [preCASignedModalOpen, setPreCASignedModalOpen] = useState(false)
  const [personalInfoModalOpen, setPersonalInfoModalOpen] = useState(false)
  const [orderStepsModalOpen, setOrderStepsModalOpen] = useState(false)
  const [selectedSection, setSelectedSection] = useState(0)

  const orderV3Props = { legalEntities } as OrderV3PageProps

  const {
    isMobile,
    isTablet,
    loading,
    orderParseError,
    docusignFetchError,
    setDocusignFetchError,
    progressState,
    contributionAgreementOneTimeLinkProps,
    isSignContributionAgreementModalOpen,
    setIsSignContributionAgreementModalOpen,
    openPrimaryCADocusignUrl,
    primaryCASigned,
    openPersonalInfoUrl,
    reRenderSwitch,
    setReRenderSwitch,
  } = useOrderV3Page(orderV3Props)

  const sidebarItems = useMemo(
    () => [
      {
        title: 'Home',
        unselectedIcon: HouseOutlinedIcon,
        selectedIcon: HouseFilledIcon,
        id: 'home',
      },
      {
        title: 'Documents',
        unselectedIcon: DocumentsIcon,
        selectedIcon: DocumentsFilledIcon,
        id: 'documents',
      },
    ],
    []
  )

  const currentSection = useInView(sidebarItems.map((item) => item.id))

  const onChangeSidebar = (newTab: number) => {
    scrollIntoView(sidebarItems[newTab].id)
  }

  useEffect(() => {
    setSelectedSection(
      sidebarItems.map((item) => item.id).indexOf(currentSection)
    )
  }, [currentSection, sidebarItems])

  if (loading) {
    return <LoadingCardPage text="Loading order..." />
  }

  const closeSignContributionAgreementModal = () => {
    setIsSignContributionAgreementModalOpen(false)
  }

  const reRenderOrderHome = () => {
    if (reRenderSwitch) {
      setReRenderSwitch(false)
    } else {
      setReRenderSwitch(true)
    }
  }

  const hasSeenPreCASignedModal =
    localStore?.getItem('hasSeenPreCASignedModal') === 'true'
  const hasSeenPostCASignedModal =
    localStore?.getItem('hasSeenPostCASignedModal') === 'true'

  if (!hasSeenPreCASignedModal && !primaryCASigned) {
    localStore?.setItem('hasSeenPreCASignedModal', 'true')
    setPreCASignedModalOpen(true)
  }

  if (
    !hasSeenPostCASignedModal &&
    primaryCASigned &&
    !progressState.isStep1Complete
  ) {
    localStore?.setItem('hasSeenPostCASignedModal', 'true')
    setPersonalInfoModalOpen(true)
  }

  if (isMobile) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        height="100vh"
        gap="16px"
        paddingLeft="16px"
        paddingRight="16px"
        sx={{
          backgroundColor: 'gray1.main',
        }}
      >
        <Typography variant="h4">
          Our mobile experience is currently under construction.
        </Typography>
        <Typography variant="p2">
          Please try again on desktop or contact{' '}
          <TrackedLink
            onClick={() => window.open(`mailto:${OM_INVESTOR_EMAIL}`)}
          >
            {OM_INVESTOR_EMAIL}
          </TrackedLink>{' '}
          for assistance.
        </Typography>
      </Box>
    )
  }

  return (
    <ThemeProvider theme={blueOvermoonTheme}>
      <PageContainer title="Overmoon | Order" trackingName="individual-order">
        <PageWrapper>
          <Grid container spacing={4} height="auto">
            {activeOrderStatuses.length > 0 && (
              <Grid item xs={12}>
                <OrderSelector />
              </Grid>
            )}
            <Grid item sm={0} md={3}>
              <Box
                display="flex"
                flexDirection="column"
                sx={{ position: 'sticky', top: '8%' }}
              >
                {!isTablet && (
                  <>
                    <Box
                      onClick={() => navigate(ACCOUNT_URL)}
                      top="28px"
                      pl="16px"
                      pb="48px"
                    >
                      <Typography
                        variant="h4"
                        fontWeight={500}
                        color="midnightBlue.main"
                        sx={{ '&:hover': { cursor: 'pointer' } }}
                      >
                        <Om721Logo width="239px" height="31px" />
                      </Typography>
                    </Box>
                    <OmSidebarWithIcons
                      items={sidebarItems}
                      onChange={onChangeSidebar}
                      selected={selectedSection}
                    />
                  </>
                )}
              </Box>
            </Grid>
            {orderParseError ? (
              <OrderErrorCard />
            ) : (
              <Grid item sm={12} md={9}>
                <DocusignErrorModal
                  isOpen={docusignFetchError}
                  onClose={() => {
                    setDocusignFetchError(false)
                  }}
                />
                <PreContributionAgreementSignedModal
                  isOpen={preCASignedModalOpen}
                  onClose={() => {
                    setPreCASignedModalOpen(false)
                  }}
                  openPrimaryCADocusignUrl={openPrimaryCADocusignUrl}
                />
                <PostContributionAgreementSignedModal
                  progressState={progressState}
                  openPersonalInfoUrl={openPersonalInfoUrl}
                  isOpen={personalInfoModalOpen}
                  onClose={() => {
                    setPersonalInfoModalOpen(false)
                  }}
                />
                <SignContributionAgreementModal
                  contributionAgreementOneTimeLinkProps={
                    contributionAgreementOneTimeLinkProps
                  }
                  isOpen={isSignContributionAgreementModalOpen}
                  onClose={closeSignContributionAgreementModal}
                  reRenderOrderHome={reRenderOrderHome}
                />
                <OrderStepsModal
                  progressState={progressState}
                  openPersonalInfoUrl={openPersonalInfoUrl}
                  isOpen={orderStepsModalOpen}
                  onClose={() => {
                    setOrderStepsModalOpen(false)
                  }}
                />
                <OrderV3HomeSection
                  id="home"
                  progressState={progressState}
                  openOrderStepsModal={() => {
                    setOrderStepsModalOpen(true)
                  }}
                />
                <Box height="50px" />
                <DocumentsSection id="documents" />
              </Grid>
            )}
          </Grid>
        </PageWrapper>
      </PageContainer>
    </ThemeProvider>
  )
}

export default OrderV3Page

/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client'

export const INVESTOR_DASHBOARD_GET_DISTRIBUTION_PREFERENCES = gql`
  query InvestorDashboardGetDistributionPreferences(
    $input: Core_GetDistributionPreferencesRequestInput!
  ) {
    getDistributionPreferences(input: $input) {
      distributionPreferences {
        uuid
        legalEntityUuid
        distributionAllowedCents
        microredemptionAllowedCents
        effectiveDate
        flexibleDistributionCents
        microredemptionAmountCents
      }
    }
  }
`

export const INVESTOR_DASHBOARD_GET_LEGAL_ENTITY = gql`
  query InvestorDashboardGetLegalEntity(
    $input: Core_GetLegalEntityRequestInput!
  ) {
    legalEntity(input: $input) {
      legalEntity {
        uuid
        name
        type
        email
        phoneNumber
        cashDistributionEnabled
        flexibleDistributionPercent
        notifyMicroredemptionsReady
        basisTheoryToken
      }
    }
  }
`

export const INVESTOR_DASHBOARD_GET_UPDATES = gql`
  query InvestorDashboardGetUpdates(
    $input: Core_GetLegalEntityUpdatesRequestInput!
  ) {
    legalEntityUpdates(input: $input) {
      legalEntityUpdates {
        documentUuid
        legalEntityUuid
        documentType
        dateAdded
        title
        subtitle
        tags
        descriptionHtml
      }
    }
  }
`

export const INVESTOR_DASHBOARD_GET_INVESTOR_UPDATES = gql`
  query InvestorDashboardGetInvestorUpdates(
    $input: Core_GetUpdatesRequestInput!
  ) {
    getUpdates(input: $input) {
      legalEntityUpdates {
        updateUuid
        legalEntityUuid
        documentUuid
        documentType
        dateAdded
        title
        subtitle
        tags
        descriptionHtml
        fileName
        filePath
        investorUuid
      }
    }
  }
`

export const INVESTOR_DASHBOARD_GET_INVESTMENT_INFO_FOR_LEGAL_ENTITY = gql`
  query InvestorDashboardGetInvestmentInfoForLegalEntity(
    $input: Core_GetInvestmentInfoForLegalEntityRequestInput!
  ) {
    getInvestmentInfoForLegalEntity(input: $input) {
      legalEntityInvestmentInfo {
        totalContribution
        totalEquityValue
        totalShareCount
        pricePerShare
      }
    }
  }
`

export const INVESTOR_DASHBOARD_GET_AUDIT_LOGS = gql`
  query InvestorDashboardGetAuditLogs($input: Core_GetAuditLogsRequestInput!) {
    auditLogs(input: $input) {
      auditLogs {
        uuid
        tableName
        operation
        previousValue
        updatedValue
        userEntity
        userEntityType
        entityTags
        createdAt
      }
    }
  }
`

export const INVESTOR_DASHBOARD_GET_EQUITY_INFO_TIMESERIES_FOR_LEGAL_ENTITY = gql`
  query InvestorDashboardGetEquityInfoTimeseriesForLegalEntity(
    $input: Core_GetTimeSeriesEquityInfoForLegalEntityRequestInput!
  ) {
    getEquityInfoTimeseriesForLegalEntity(input: $input) {
      aggregateEquityInfo {
        aggregateEquityValue
        aggregateContributions
        aggregateAppreciation
        aggregateRedemptions
        effectiveDateTime
      }
    }
  }
`

export const INVESTOR_DASHBOARD_GET_MOST_RECENT_ORDER_STATUS_FOR_LEGAL_ENTITY = gql`
  query InvestorDashboardGetMostRecentOrderStatusForLegalEntity(
    $input: Core_GetMostRecentOrderStatusForLegalEntityRequestInput!
  ) {
    getMostRecentOrderStatusForLegalEntity(input: $input) {
      order {
        orderUuid
        status
        addresses {
          zipcode
          state
          street
          streetNumber
          unit
          city
        }
      }
    }
  }
`

export const INVESTOR_DASHBOARD_GET_LEGAL_ENTITY_TAX_SUMMARY = gql`
  query GetLegalEntityTaxSummary(
    $input: Core_GetLegalEntityTaxSummaryRequestInput!
  ) {
    getLegalEntityTaxSummary(input: $input) {
      currentCostBasisCents
      totalDepreciationCents
      totalTaxableIncomeCents
      totalEmbeddedGainCents
      totalExpectedTaxableIncomeCents
    }
  }
`

import React, { useState } from 'react'
import { Box, Menu, MenuItem, styled } from '@mui/material'
import LogoutIcon from '@mui/icons-material/Logout'
import AccountBalanceIcon from '@mui/icons-material/AccountBalance'
import AccountBoxIcon from '@mui/icons-material/AccountBox'
import { TrackedLink } from '@flock/shared-ui'
import { useFirebaseAuth } from '@flock/utils'
import GearIcon from '../../images/settings.svg'
import InvestorAccountSettingsModal from './Settings/InvestorAccountSettingsModal'
import SettingsModal from './Settings/SettingsModal'

const Header = styled('div')({
  display: 'flex',
  justifyContent: 'end',
})

const GearOutline = styled('img')({
  height: 'calc(10% + 15px)',
  width: 'calc(10% + 15px)',
})

const HeaderSection = (props: { isMobile?: boolean }) => {
  const { isMobile } = props
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null)
  const [
    investorAccountSettingsModalOpen,
    setInvestorAccountSettingsModalOpen,
  ] = useState(false)
  const [legalEntitySettingsModalOpen, setLegalEntitySettingsModalOpen] =
    useState(false)
  const { logout } = useFirebaseAuth()

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  return (
    <Header>
      <Box display="flex" sx={{ alignItems: 'center', gap: '36px' }}>
        {!isMobile && (
          <Box
            display="flex"
            sx={{
              alignItems: 'center',
              gap: '5px',
              '&:hover': { cursor: 'pointer' },
            }}
            onClick={handleClick}
          >
            <>
              <GearOutline
                sx={{ height: '24px', width: '24px' }}
                src={GearIcon}
              />
              <TrackedLink
                variant="p1"
                sx={{
                  color: '#0C1D3F!important',

                  '&:hover': {
                    color: '#0C1D3F!important',
                  },
                }}
              >
                Settings
              </TrackedLink>
            </>
          </Box>
        )}
      </Box>
      <Menu
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem onClick={() => setInvestorAccountSettingsModalOpen(true)}>
          <AccountBoxIcon sx={{ paddingRight: '.5rem' }} />
          Investor Settings
        </MenuItem>
        <MenuItem onClick={() => setLegalEntitySettingsModalOpen(true)}>
          <AccountBalanceIcon sx={{ paddingRight: '.5rem' }} />
          Distribution Settings
        </MenuItem>
        <MenuItem onClick={logout}>
          <LogoutIcon sx={{ paddingRight: '.5rem' }} />
          Logout
        </MenuItem>
      </Menu>
      <InvestorAccountSettingsModal
        open={investorAccountSettingsModalOpen}
        onClose={() => setInvestorAccountSettingsModalOpen(false)}
      />
      <SettingsModal
        open={legalEntitySettingsModalOpen}
        onClose={() => setLegalEntitySettingsModalOpen(false)}
      />
    </Header>
  )
}

HeaderSection.defaultProps = {
  isMobile: false,
}

export default HeaderSection

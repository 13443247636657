import { Core_LegalEntityUpdate } from '@flock/flock-gql-server/src/__generated__/graphql'
import {
  CloseMobileIcon,
  OmLeftArrowFilledIcon,
  TrackedButton,
  TrackedLink,
} from '@flock/shared-ui'
import { Close } from '@mui/icons-material'
import {
  Box,
  Grid,
  IconButton,
  Modal,
  Paper,
  styled,
  SwipeableDrawer,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import React, { useState } from 'react'

type HomeMessageProps = {
  message: Core_LegalEntityUpdate
  modal?: boolean
  viewAllMessages?: boolean
}

const ModalWrapper = styled(Paper)({
  position: 'absolute',
  top: '40%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '544px',
  height: '324px',
  maxHeight: 'calc(100vh - 512px)',
  overflowY: 'auto',
  padding: '48px',
  borderRadius: '24px',
})

const HomeMessage = (props: HomeMessageProps) => {
  const { message, modal, viewAllMessages } = props
  const [messageOpen, setMessageOpen] = useState(false)

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <>
      <Box
        display="flex"
        flexDirection="row"
        gap="8px"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        onClick={() => setMessageOpen(true)}
        sx={{ '&:hover': { cursor: 'pointer' } }}
      >
        <Box display="flex" flexDirection="row" gap="8px">
          <Typography
            variant="c1"
            height="24px"
            width="64px"
            sx={{
              fontSize: '12px',
              fontWeight: 500,
              textAlign: 'center',
              background:
                message!.tags![0] === 'UPDATE' ? '#FF9E00' : '#8778D9',
              color: 'white!important',
              borderRadius: '8px',
              minWidth: '64px',
            }}
          >
            {message!.tags![0]}
          </Typography>
          <Box
            width={isMobile ? '200px' : undefined}
            sx={{
              overflow: 'hidden',
              whieSpace: 'nowrap',
              textOverflow: 'ellipsis',
            }}
          >
            <TrackedLink
              sx={{
                maxWidth: modal ? '360px' : '320px',
                overflow: 'hidden',
                width: '100%',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                color: 'gray8.main',
              }}
              onClick={() => setMessageOpen(true)}
              variant="p2"
            >
              {message.subtitle}
            </TrackedLink>
          </Box>
        </Box>
        <Box
          height="24px"
          width="24px"
          sx={{
            transform: `scaleX(-1)`,
          }}
        >
          <OmLeftArrowFilledIcon height="100%" width="100%" />
        </Box>
      </Box>

      {isMobile ? (
        <SwipeableDrawer
          onOpen={() => {}}
          onClose={() => {}}
          open={messageOpen}
          // onClose={() => setMessageOpen(false)}
          PaperProps={{ sx: { width: '100%' } }}
        >
          <Box position="fixed" top="8px" right="8px">
            <IconButton onClick={() => setMessageOpen(false)}>
              <CloseMobileIcon />
            </IconButton>
          </Box>
          <Grid columns={4} spacing={2} pl="32px" pr="32px">
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              height="100%"
              pt="64px"
            >
              <Box display="flex" flexDirection="column" gap="24px">
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                >
                  <Typography variant="h4">{message.title}</Typography>
                </Box>
                <Box
                  id="testID"
                  sx={{
                    '& >p >span': {
                      fontFamily: 'Outfit!important',
                      fontSize: '16px!important',
                    },
                    '& >p': {
                      fontFamily: 'Outfit!important',
                      fontSize: '16px!important',
                    },
                  }}
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: message?.descriptionHtml as string,
                  }}
                />
              </Box>
            </Box>
          </Grid>
        </SwipeableDrawer>
      ) : (
        <Modal
          open={messageOpen}
          onClose={() => setMessageOpen(false)}
          BackdropProps={{
            invisible: viewAllMessages,
          }}
        >
          <ModalWrapper>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              height="100%"
            >
              <Box display="flex" flexDirection="column" gap="24px">
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                >
                  <Typography variant="h4">{message.title}</Typography>
                  <IconButton
                    sx={{
                      height: '24px',
                      marginTop: '-18px',
                      marginRight: '-18px',
                    }}
                    onClick={() => setMessageOpen(false)}
                  >
                    <Close />
                  </IconButton>
                </Box>
                <Box
                  sx={{
                    '& >p >span': {
                      fontFamily: 'Outfit!important',
                      fontSize: '16px!important',
                    },
                    '& >p': {
                      fontFamily: 'Outfit!important',
                      fontSize: '16px!important',
                    },
                  }}
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: message?.descriptionHtml as string,
                  }}
                />
              </Box>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="flex-end"
                paddingBottom="16px"
              >
                <TrackedButton
                  sx={{ width: '113px', height: '40px' }}
                  onClick={() => setMessageOpen(false)}
                  variant="primary"
                >
                  <Typography variant="cta2">Close</Typography>
                </TrackedButton>
              </Box>
            </Box>
          </ModalWrapper>
        </Modal>
      )}
    </>
  )
}

HomeMessage.defaultProps = {
  modal: false,
  viewAllMessages: false,
}
export default HomeMessage
